import { Paper, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withTheme(styled.div`
  max-width: 31rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    max-width: 40rem;
  }
`);

export const CourseProduct = styled(Paper)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  min-height: fit-content;
  height: 5rem;
  width: 100%;
`;

export const CouseName = styled.div`
  margin: 2rem;
  flex: 1;
`;

export const CoursePrice = withTheme(styled.span`
  background: ${({ theme }) => theme.palette.primary.main};
  color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 2rem;
`);
