import styled from 'styled-components';

export const Container = styled.div`
  background-color: #232478;
  overflow: hidden;
  padding: 1rem;
  h5 {
    color: #fff;
  }
`;

export const Content = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  line-height: 1.5rem;

  h1 {
    color: #fff;
  }

  h2 {
    color: #fff;
    margin-top: 1.5rem;
    font-size: 1.6rem;
  }

  p {
    color: #fff;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    font-size: 1rem;
  }

  svg {
    margin-top: 5px;
    margin-right: 5px;
    width: 30%;
    height: 30%;
    fill: #fff;
  }
`;

export const NavContainer = styled.nav`
  display: flex;
  flex-direction: column;

  margin-top: 1rem;

  a {
    font-size: 1.5rem;
    color: #ffffff;
    margin-bottom: 5px;
  }
`;

export const Credits = styled.div`
  background: #000;
  padding: 0.5rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  color: #fff;
  width: 100%;
  font-size: 1.2rem;
  a {
    margin-left: 0.5rem;
  }
`;
