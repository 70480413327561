import parser from 'xml2json';
import api from './_api';

interface CallbackPagSeguro {
  success: (transactionCode: string) => void;
  abort: () => void;
}

declare let PagSeguroLightbox: (
  code: string,
  callback: CallbackPagSeguro
) => boolean;

export function openLightBox(code: string): void {
  window.location.href = `https://pagseguro.uol.com.br/v2/checkout/payment.html?code=${code}`;
  // const callback = {
  //   success: (transactionCode: string) => {
  //     // Insira os comandos para quando o usuário finalizar o pagamento.
  //     // O código da transação estará na variável "transactionCode"
  //     console.log(
  //       `Compra feita com sucesso, código de transação: ${transactionCode}`
  //     );
  //   },
  //   abort: () => {
  //     // Insira os comandos para quando o usuário abandonar a tela de pagamento.
  //     ;
  //   },
  // };
  // ;
  // const isOpenLightbox = PagSeguroLightbox(code, callback);

  // if (!isOpenLightbox) {

  // }
}
