import { Typography } from '@material-ui/core';
import React from 'react';
import MuiContainer from '@material-ui/core/Container';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Header } from './styles';
import MyProfile from './MyProfile';
import MyPayments from './MyPayments';
import TabBar from '../../components/TabBar';
import MyCourses from './MyCourses';
import { ApplicationState } from '../../store/types';

const MyAccount: React.FC = () => {
  const { path } = useRouteMatch();

  const user = useSelector((state: ApplicationState) => state.profile.userData);

  return user ? (
    <MuiContainer maxWidth="lg">
      <Container>
        <Header>
          <Typography variant="h2">Minha Conta</Typography>
          <Typography variant="h4" color="textSecondary">
            {user && user.name}
          </Typography>
        </Header>
        <TabBar />
        <Switch>
          <Route path={`${path}/cursos`}>
            <MyCourses />
          </Route>
          <Route path={`${path}/pagamentos`}>
            <MyPayments />
          </Route>
          <Route path={`${path}/alterar-dados`}>
            <MyProfile />
          </Route>
        </Switch>
      </Container>
    </MuiContainer>
  ) : (
    <Redirect to="/" />
  );
};

export default MyAccount;
