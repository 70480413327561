import {
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
} from '@material-ui/core';
import React, { useState } from 'react';
import { Taxonomy } from '../../common/interfaces/taxonomy';

// import { Container } from './styles';

interface CoursesFilterProps {
  data: Taxonomy[];
  onListItemClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => void;
  selectedItem: number;
}

const CoursesFilter: React.FC<CoursesFilterProps> = ({
  data = [],
  onListItemClick,
  selectedItem,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="filter">
      <Button id={id} variant="contained" color="primary" onClick={handleClick}>
        Filtrar cursos
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItem
            button
            selected={selectedItem === 0}
            onClick={(event) => {
              setAnchorEl(null);
              onListItemClick(event, 0);
            }}
          >
            <ListItemText primary="Todos os cursos" />
          </ListItem>
          {data.map((tax) => (
            <ListItem
              key={tax.term_id}
              button
              selected={selectedItem === tax.term_id}
              onClick={(event) => {
                setAnchorEl(null);
                onListItemClick(event, tax.term_id);
              }}
            >
              <ListItemText primary={tax.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default CoursesFilter;
