import React, { useEffect } from 'react';
import './config/ReactotronConfig';

import { BrowserRouter, Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import './styles/montserrat.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/index';
import GlobalStyles from './styles/global';
import materialTheme from './styles/materialTheme';
import LoginPopupProvider from './common/providers/LoginPopupProvider';
import Routes from './routes';
import CartProvider from './common/providers/CartProvider';
import history from './services/history';
import WhatsappIcon from './components/WhatsappIcon';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={materialTheme}>
          <LoginPopupProvider>
            <CartProvider>
              <BrowserRouter basename={baseUrl || '/'}>
                <Routes />
                <GlobalStyles />
                <CssBaseline />
              </BrowserRouter>
              <WhatsappIcon />
            </CartProvider>
          </LoginPopupProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
