import { store } from '../store';
import { logoutSuccess } from '../store/modules/profile/action';
import history from './history';

export function logoutUser() {
  history.push('/', {
    loggedOut: true,
  });
  store.dispatch(logoutSuccess());
}
