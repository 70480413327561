import React from 'react';
import { Typography } from '@material-ui/core';

import AboutUsImage from '../../assets/AboutUsImage.jpg';

import { Container, Content, ContentContainer, Image } from './styles';

const AboutUs: React.FC = () => {
  return (
    <Container>
      <Typography variant="h1" component="h1">
        Quem Somos
      </Typography>

      <ContentContainer>
        <Image src={AboutUsImage} />
        <Content>
          <Typography variant="body1" component="p">
            A Certifique Cursos é uma escola on line, especializada em cursos
            classificados como¨ livres¨.
          </Typography>
          <Typography variant="body1" component="p">
            Somos associados à ABED ¨Associação de Educação a Distância¨ e
            oferecemos cursos nas mais diversas áreas através de um sistema
            prático e de fácil acesso.
          </Typography>
          <Typography variant="body1" component="p">
            Nossos cursos e provas são totalmente gratuitos e nosso certificado
            (opcional) é aceito em todo o Brasil com o menor custo do mercado.
          </Typography>
          <Typography variant="body1" component="p">
            Nossa missão é promover um estudo de qualidade, capacitando as
            pessoas para o mercado de trabalho independentemente do seu grau de
            formação.
          </Typography>
          <Typography variant="body1" component="p">
            Temos nossa atuação Autorizada de acordo com a Lei nº 9.394/96 e
            Decreto Presidencial nº 5.154/04.
          </Typography>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default AboutUs;
