import styled from 'styled-components';
import { Container as MuiContainer, withTheme } from '@material-ui/core';

export const Container = styled(MuiContainer)``;

export const SearchContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 4rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
  }
`);

export const FilterContainer = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  .filter {
    margin-top: 1rem;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 0rem;
    flex-direction: row;

    .filter {
      margin-top: 0rem;

      margin-left: 1rem;
    }
  }
`);

export const CoursesSection = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 2rem 0;
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 6rem;
`;
