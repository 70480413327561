import React from 'react';
import { Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';

import AboutUsImage from '../../assets/AboutUsImage.jpg';

import {
  Container,
  Content,
  ContentContainer,
  Image,
  CheckContainer,
  Check,
} from './styles';

const CertificateOnline: React.FC = () => {
  return (
    <Container>
      <Typography variant="h1" component="h1">
        Nosso certificado
      </Typography>

      <ContentContainer>
        <Image src={AboutUsImage} />
        <Content>
          <Typography variant="body1" component="p">
            A certifique Cursos é uma escola de cursos on line que, além de
            promover um ensino de excelente qualidade , também oferece aos seus
            alunos um certificado válido em todo o território nacional com o
            menor custo do mercado.
          </Typography>
          <Typography variant="body1" component="p">
            Valor do certificado digital: <strong>R$ 43,90</strong>
          </Typography>
          <Typography variant="body1" component="p">
            O certificado ficará disponível para download na área do aluno, após
            a confirmação de pagamento. No caso de pagamento por boleto, a
            confirmação ocorrerá em torno de 24 horas, caso o aluno tenha
            urgência, o mesmo deverá enviar o comprovante de pagamento através
            do nosso whatsApp.
          </Typography>
          <Typography variant="body1" component="p">
            Valor do certificado digital + impresso : <strong>R$78,90</strong>
          </Typography>
          <Typography variant="body1" component="p">
            É de total responsabilidade do aluno o preenchimento correto do
            endereço para a entrega do certificado impresso. A Certifique Cursos
            não se responsabiliza por certificados não entregues causados por
            endereços incompletos ou errados. O certificado impresso será
            enviado por carta registrada, o prazo dos correios é de 05 a 20 dias
            úteis, dependendo da localidade O aluno deverá informar o não
            recebimento do certificado em até 45 dias, caso contrário será
            cobrado uma segunda via.
          </Typography>
          <Typography variant="h3">WhatsApp:(35)98866-0200</Typography>
          <Check>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Educação continuada
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Extensão universitária
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Titularidade para professores
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Complementação de conhecimento
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Ascensão de carreira ou cargo
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Contagem de carga horária para estágio
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Comprovação de Prova de Título
              </Typography>
            </CheckContainer>
            <CheckContainer>
              <CheckIcon />
              <Typography variant="body1" component="p">
                Enriquecimento de currículo e etc...
              </Typography>
            </CheckContainer>
          </Check>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default CertificateOnline;
