import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withTheme(styled.div`
  min-width: 29rem;
  max-height: 50rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 40rem;
  }
`);

export const CartItems = styled.div``;

export const TotalContainer = styled.div`
  display: flex;
  margin: 1rem;
  justify-content: flex-end;
  font-size: 1.4rem;
  align-items: flex-end;
`;

export const Total = styled.div``;

export const CartItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  padding: 1rem 2rem;
  line-height: 1.2;
`;

export const CourseName = styled.div``;

export const CertificateType = styled.div``;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Value = styled.span`
  margin-right: 1rem;
`;

export const TotalText = styled.span`
  font-weight: bold;
  font-size: 1.8rem;
  margin-left: 0.5rem;
`;
