import styled from 'styled-components';

export const Container = styled.a`
  position: fixed;
  bottom: 30px;
  left: 10px;
  z-index: 1000;
  background: #06d755;

  border-radius: 50px;
  padding: 10px 12px;
`;
