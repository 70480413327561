import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@material-ui/core';

import TreeView from '../../components/TreeView';

import { Container, Content } from './styles';
import certifiqueApi from '../../services/certifiqueApi';
import Faq from '../../common/interfaces/faq';

const AboutUs: React.FC = () => {
  const [faq, setFaq] = useState<Faq[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    certifiqueApi
      .getFaq()
      .then((res) => setFaq(res))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <Typography variant="h1" component="h2">
        Perguntas frequentes
      </Typography>
      {!loading ? (
        <Content>
          {faq.map((e) => (
            <TreeView
              key={e.text_title}
              item={e.text_title}
              contents={[e.question]}
            />
          ))}

          {/* <TreeView
          item="O curso é realmente grátis?"
          contents={[
            'Todos os cursos são gratuitos, mas os certificados não (R$43.90).Os cursos consistem em 3 fases: estudo das apostilas, realização do teste e avaliação .O aluno pode se inscrever em quantos cursos desejar ,pois não existe taxa de matrícula e nem mensalidade.',
          ]}
        />

        <TreeView
          item="O Certificado é Reconhecido pelo MEC?"
          contents={[
            'O MEC só reconhece cursos de graduação e pós graduação.  Os nossos cursos, assim como os cursos das outras  escolas do mesmo segmento , são classificados como cursos LIVRES  e não necessitam de reconhecimento do MEC. Somos associados a ABED( Associação Brasileira de Educação a Distância).',
          ]}
        />
        <TreeView
          item="Qual o Valor do Certificado?"
          contents={[
            'Certificado digital: R$43.90',
            'Certificado digital +impresso: R$78,90',
          ]}
        />
        <TreeView
          item="O Certificado é válido em todo Brasil?"
          contents={[
            'Sim, somos uma instituição associada à ABED (Associação Brasileira de Educação a Distância) e nossos Certificados têm validade em todo o território nacional.',
          ]}
        />
        <TreeView
          item="Quais as Formas de Pagamento?"
          contents={[
            'O pagamento é feito pelo Pagseguro através de cartão de crédito e boleto , ou por depósito bancário. Em caso de boleto o aluno poderá pagar em qualquer banco ou casas lotéricas até a data do vencimento.',
          ]}
        />
        <TreeView
          item="Como Funcionam os Cursos?"
          contents={[
            'Os Cursos são totalmente On-line, o aluno estuda em casa e para ser aprovado precisa alcançar uma média de 60 % na avaliação. Aprovado, ele poderá solicitar o seu certificado.',
          ]}
        />
        <TreeView
          item="Como me cadastrar no site?"
          contents={[
            'Na página principal no canto superior direito da tela clicar em¨ cadastre-se¨ , depois é só colocar os dados solicitados e se matricular em quantos cursos desejar .',
          ]}
        />
        <TreeView
          item="Como vejo a nota da minha avaliação?"
          contents={[
            'Sua nota aparecerá automaticamente após a conclusão do teste.',
          ]}
        />
        <TreeView
          item="Não gostei da minha nota, posso realizar o teste novamente?"
          contents={[
            'Sim, o aluno poderá realizar o teste quantas vezes desejar.',
          ]}
        />
        <TreeView
          item="Qual a carga horária dos cursos?"
          contents={[
            'A carga horária varia de acordo com o material de estudo de cada curso, podendo ser de 50 a 250 horas.',
          ]}
        />
        <TreeView
          item="Qual o horário de atendimento?"
          contents={[
            'O atendimento é das 09:00 às 20:00 horas de segunda a sexta-feira e das 09:00 às 17:00 no sábado, exceto feriados.',
          ]}
        />
        <TreeView
          item="Por que o certificado é pago?"
          contents={[
            ' A Certifique cursos é uma empresa privada. Pagamos várias despesas para funcionar, dentre essas despesas podemos citar:',
            'Imposto, funcionários, internet, água, luz, telefone e etc… A taxa cobrada pela emissão do certificado é o que mantém todos esses custos.',
          ]}
        /> */}
        </Content>
      ) : (
        <Box display="flex" width="100%" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default AboutUs;
