import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import MessageBox from '../../components/MessageBox';
import { ApplicationState } from '../../store/types';
import { formatNumberToBRL } from '../../common/utils/formatNumber';
import {
  Container,
  CartItems,
  TotalContainer,
  CartItemContainer,
  LeftContainer,
  Value,
  TotalText,
} from './styles';
import { CartItem } from '../../common/interfaces/cartItem';
import { removeFromCart } from '../../store/modules/cart/action';
import certifiqueApi from '../../services/certifiqueApi';

interface CartProps {
  isOpen: boolean;
  onClose: (event: React.SyntheticEvent<{}, Event>) => void;
}

const Cart: React.FC<CartProps> = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const items = useSelector((state: ApplicationState) => state.cart.items);
  const dispatch = useDispatch();

  const total = useMemo(() => {
    return items.reduce((t, item) => {
      return t + item.product.price;
    }, 0);
  }, [items]);

  const handleDeleteItem = (item: CartItem) => {
    dispatch(removeFromCart(item.finishedCourse.id));
  };

  const handlePayment = () => {
    setLoading(true);
    setError(false);

    certifiqueApi
      .checkout(items)
      .then(() => {

      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <MessageBox isOpen={isOpen} onClose={onClose}>
      <Container>
        <Typography variant="h4">Carrinho</Typography>

        <CartItems>
          {items.map((item) => (
            <CartItemContainer key={item.courseName}>
              <div>
                <Typography variant="body1">{item.courseName}</Typography>
                <Typography variant="caption">{item.product.name}</Typography>
              </div>
              <LeftContainer>
                <Value>{formatNumberToBRL(item.product.price)}</Value>
                <IconButton onClick={() => handleDeleteItem(item)}>
                  <Cancel fontSize="small" color="error" />
                </IconButton>
              </LeftContainer>
            </CartItemContainer>
          ))}
        </CartItems>

        <TotalContainer>
          TOTAL <TotalText>{formatNumberToBRL(total)}</TotalText>
        </TotalContainer>
        {error && (
          <Alert severity="error" style={{ margin: '1rem 0' }}>
            Ocorreu um erro, por favor tente novamente
          </Alert>
        )}
        <Box m="0 auto 0">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePayment}
            disabled={loading || !items.length}
          >
            Ir para o pagamento
          </Button>
        </Box>
      </Container>
    </MessageBox>
  );
};

export default Cart;
