import styled from 'styled-components';
import {
  Container as MuiContainer,
  withStyles,
  withTheme,
} from '@material-ui/core';

export const Container = styled(MuiContainer)`
  margin-top: 2rem;
  h1 {
    margin-top: 2rem;
    text-align: center;
    font-size: 24px;
  }
`;

export const Content = withTheme(styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;

  button {
    margin-top: 1rem;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    display: grid;
    grid-template-columns: 1fr 200px;
    grid-gap: 2rem;

    margin-top: 4rem;
    margin-bottom: 2rem;
    button {
      margin-top: 0rem;
    }
  }

  input {
    padding: 10px;
  }
`);
