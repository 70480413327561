import React, { useCallback, useState } from 'react';
import CartIconButton from '../../components/CartIconButton';
import Cart from '../../pages/Cart';

const initialState = {
  showCart: (): any => {},
  closeCart: (): any => {},
};
export const cartContext = React.createContext(initialState);
const { Provider } = cartContext;

const CartProvider: React.FC = ({ children }) => {
  const [cartState, setCartState] = useState({
    isOpen: false,
  });

  const close = (): void => {
    setCartState((p) => ({ ...p, isOpen: false }));
  };

  const showCart = useCallback(() => {
    setCartState({
      isOpen: true,
    });
  }, []);

  const contextValue = {
    showCart,
    closeCart: close,
  };

  const handleOpenCart = () => {
    showCart();
  };

  return (
    <>
      <Provider value={contextValue}>{children}</Provider>
      <Cart {...cartState} onClose={close} />
      <CartIconButton onClick={handleOpenCart} />
    </>
  );
};

export default CartProvider;
