import styled from 'styled-components';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Typography, TypographyTypeMap, InputBase } from '@material-ui/core';
import { withTheme } from '@material-ui/styles';

type TextInputWrapperProps = {
  messageType?: 'warning' | 'success' | 'error';
};

export const TextInputWrapper = withTheme(styled.div<TextInputWrapperProps>`
  &:focus-within {
    label {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`);

type LabelWrapperProps = {
  small?: boolean;
};
export const LabelWrapper = withTheme(styled.div<LabelWrapperProps>`
  display: flex;
  flex-direction: row;
  padding: ${(props): string => (props.small ? '0 1rem' : '0 2rem')};
  align-items: center;
  margin-bottom: 0.6rem;
  svg {
    margin-left: 0.7rem;
    color: ${({ theme }) => theme.palette.primary.main};
    z-index: 2;
    display: block;
  }
`);

type TextInputLabelProps = {
  labelPosition?: string;
  small?: boolean;
  messageType?: 'warning' | 'success' | 'error';
};

export const TextInputLabel = withTheme(styled.label<TextInputLabelProps>`
  color: ${({ small, messageType, theme }): string => {
    if (small) {
      return '#878B9E';
    }
    if (messageType) {
      return theme.palette[messageType].main;
    }

    return theme.palette.primary.light;
  }};
  font-size: ${({ small }): string => (small ? '1.2rem' : '1.4rem')};
  font-weight: 500;
  display: inline-block;
`);

type TextInputContainerProps = {
  borderRadius?: string;
  borderColor?: string;
  shadow?: string;
  small?: boolean;
  messageType?: 'warning' | 'success' | 'error';
};
export const TextInputContainer = withTheme(styled.div<TextInputContainerProps>`
  background: #f5f6fa;
  padding: ${({ small }): string => (small ? '0.5rem 1rem' : '1rem 2rem')};
  display: flex;
  flex-direction: row;
  color: #000;
  border-radius: ${({ borderRadius }): string => borderRadius || '10px'};
  border: 2px solid;
  border-color: ${({ borderColor }): string => borderColor || '#707070'};

  &:focus-within {
    border-color: ${({ borderColor, theme }): string =>
      borderColor || theme.palette.primary.main};
  }

  ${({ messageType, theme }): string =>
    (messageType && `border: 2px solid ${theme.palette[messageType].main};`) ||
    ''}

  align-items: center;
  justify-content: space-between;
  ${({ shadow }): string | undefined => shadow}

  &::placeholder {
    color: #a1a5bc !important;
  }

  input {
    background: none;
    border: none;
    width: 100%;
    color: #000;
    &:disabled {
      color: #777 !important;
    }
  }

  svg {
    font-size: 2rem;
  }
`);

export const TooltipWrapper = styled.div`
  position: relative;
`;

export const TooltipButton = withTheme(styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  svg {
    margin-left: 0.7rem;
    color: ${({ theme }) => theme.palette.primary.main};
    z-index: 2;
    display: block;
    font-size: 20px;
  }
`);

interface TooltipTextProps
  extends OverridableComponent<TypographyTypeMap<{}, 'span'>> {
  height?: string | number;
}

export const TooltipTextWrapper = styled.div`
  position: absolute;
`;

export const TooltipText = styled(Typography)`
  display: ${(props: TooltipTextProps): string | number =>
    props.height ? 'inline-block' : 'hidden'};
  word-wrap: break-word;

  position: absolute;
  top: calc(
    -${(props: TooltipTextProps): string | number => props.height || ''}px + 5px
  );
  left: 35px;
  z-index: 1101;
  background: #000;
  color: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  max-width: 185px;
  min-width: 130px;

  &::before {
    content: '';
    position: absolute;
    left: -6px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #000;
  }
`;

export const IconLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  svg {
    &:first-child {
      margin-right: 12px;
    }
  }
`;

export const Input = styled(InputBase)`
  font-size: 14px;
  width: 100%;
  padding: 0;
`;

export const FeedBackMessageWrapper = withTheme(styled.div`
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.2rem;
  color: ${({ theme }): string => theme.palette.text.secondary};
`);

interface InfoMessageProps {
  color: string;
}

export const InfoMessage = styled.div<InfoMessageProps>`
  color: ${({ color }): string => color};
  font-size: 1rem;
  padding-left: 1.5rem;
  padding-top: 0.3rem;
  font-weight: 500;
  @media only screen and (min-width: 1280px) {
    font-size: 1.2rem;
    padding-left: 2rem;
  }
`;
