import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

export const Container = styled(MuiContainer)`
  padding-top: 3rem;
`;

export const Content = styled.div`
  margin-top: 4rem;
  margin-bottom: 5rem;
`;
