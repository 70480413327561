import { Container as MuiContainer, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withTheme(styled.div`
  display: block;

  max-width: ${({ theme }) => theme.breakpoints.values.sm}px;
  margin: 0 auto;
`);

export const Section = styled.section`
  margin-top: 1.5rem;
  .input-field {
    margin-bottom: 1.5rem;
  }
`;
