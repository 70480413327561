import produce from 'immer';
import { CartTypes, CartActionsTypes, CartState } from './types';

export const INITIAL_STATE: CartState = {
  items: [],
};

export default function user(
  state = INITIAL_STATE,
  action: CartActionsTypes
): CartState {
  return produce(state, (draft) => {
    switch (action.type) {
      case CartTypes.ADD_TO_CART: {
        const index = draft.items.findIndex(
          (e) => e.finishedCourse.id === action.payload.item.finishedCourse.id
        );

        if (index < 0) {
          draft.items.push(action.payload.item);
        } else {
          draft.items[index] = action.payload.item;
        }
        break;
      }
      case CartTypes.REMOVE_FROM_CART: {
        draft.items = draft.items.filter(
          (e) => e.finishedCourse.id !== action.payload.id
        );
        break;
      }

      case CartTypes.CLEAR_CART: {
        draft.items = [];
        break;
      }
      default: {
        break;
      }
    }
  });
}
