import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Container, CloseButton, Modal } from './styles';
import Login from './Login';
import Register from './Register';
import Recover from './Recover';
import { Step } from './types';

type Alert = {
  severity: 'warning' | 'success' | 'info' | 'error';
  text: string;
};
export interface LoginPopupProps {
  onClose?: () => void;
  isOpen?: boolean;
  step: Step;
  alert?: Alert;
}

const LoginPopup: React.FC<LoginPopupProps> = ({
  onClose,
  isOpen,
  step: receivedStep,
  alert: receivedAlert,
}) => {
  const [step, setStep] = useState<Step>(receivedStep);
  const [alert, setAlert] = useState<Alert | undefined>(receivedAlert);

  useEffect(() => {
    setStep(receivedStep);
  }, [receivedStep]);

  useEffect(() => {
    setAlert(receivedAlert);
  }, [receivedAlert]);

  const handleRegisterFinish = useCallback(() => {
    setStep('login');
    setAlert({
      severity: 'success',
      text:
        'Seu cadastro foi realizado com sucesso! Faça seu login para continuar',
    });
  }, []);

  const renderStep = useCallback(() => {
    ;
    const stepToRender = {
      login: <Login onFinish={onClose} alert={alert} setStep={setStep} />,
      register: <Register onFinish={handleRegisterFinish} setStep={setStep} />,
      recover: <Recover onFinish={onClose} setStep={setStep} />,
    };

    return stepToRender[step];
  }, [alert, handleRegisterFinish, onClose, step]);

  return (
    <Modal open={isOpen || false} onBackdropClick={onClose}>
      <Container>
        <CloseButton onClick={onClose}>
          <Clear fontSize="small" />
        </CloseButton>

        {renderStep()}
      </Container>
    </Modal>
  );
};

export default LoginPopup;
