import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  outlined?: boolean;
}

export const Container = styled.button<ContainerProps>`
  width: ${(props) => (props.className === 'blue' ? '100%' : null)};
  height: 5.6rem;
  padding: 0 2rem;

  background-color: ${(props) =>
    props.className === 'blue' ? 'rgba(44, 48, 88, 0.0)' : '#ffffff'};
  color: #2c2f58;
  font-weight: bold;
  font-size: ${(props) => (props.className === 'blue' ? '1.5rem' : '1.8rem')};
  border: ${(props) =>
    props.className === 'blue' ? '3px solid #232478' : '0px'};
  border-radius: 2rem;
  opacity: 1;
  transition: background-color 0.2s;

  ${({ outlined }) =>
    outlined &&
    css`
      color: #ffffff;
      border: 3px solid #fff;
      background: none;
    `}

  &:hover {
    background: ${shade(0.1, 'rgba(255,255,255, 0.8)')};
  }
`;
