import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends ButtonProps {
  colortype?: string;
  outlined?: boolean;
}

const Button: React.FC<Props> = ({
  children,
  colortype,
  outlined,
  ...rest
}) => {
  return (
    <Container
      type="button"
      className={colortype}
      outlined={outlined}
      {...rest}
    >
      {children}
    </Container>
  );
};

export default Button;
