import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withTheme(styled.label`
  border: 2px solid #eee;
  border-radius: 50px;
  padding: 5px;
  border: 2px solid ${({ theme }) => theme.palette.primary.light};
  display: flex;
  flex-direction: row;
  align-items: center;
  :focus-within {
    border: 2px solid ${({ theme }) => theme.palette.primary.main};
  }
`);

export const Input = styled.input`
  border: none;
  font-size: 1.4rem;
  background: none;
  flex: 1;
`;
