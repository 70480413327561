import styled from 'styled-components';
import { shade } from 'polished';

export const Wrapper = styled.header`
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #232479;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }
`;

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  max-width: 1280px;

  a.link {
    font-size: 1.8rem;
    color: #ffffff;
    opacity: 1;
    margin: 1.5rem;
    transition: color 0.2s;

    &:hover {
      color: ${shade(0.3, '#ffffff')};
    }
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
