import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownload } from '@material-ui/icons';
import { Helmet } from 'react-helmet';
import SubtitleBar from '../../components/SubtitleBar';
import {
  Container,
  Image,
  InnerContainer,
  FreeText,
  ButtonsContainer,
  LinkContainer,
} from './styles';
import certifiqueApi from '../../services/certifiqueApi';
import Course from '../../common/interfaces/course';
import placeholder from '../../assets/teste.jpg';
import { FinishedCourse } from '../../common/interfaces/finishedCourse';
import CourseFinishedPopup from '../../components/CourseFinishedPopup';
import { Product } from '../../common/interfaces/product';
import { addToCart } from '../../store/modules/cart/action';
import useCart from '../../common/hook/useCart';
import { ApplicationState } from '../../store/types';
import useLogin from '../../common/hook/useLogin';
import MessageBox from '../../components/MessageBox';

const CoursePage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation<{
    finishedCourse?: FinishedCourse;
    quizWithError?: number;
  }>();
  const [course, setCourse] = useState<Course>();
  const [finishedCourse, setFinishedCourse] = useState<FinishedCourse>();
  const [popup, setPopup] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showDocs, setShowDocs] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [quizWithError, setQuizWithError] = useState(
    location.state?.quizWithError
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const cart = useCart();
  const login = useLogin();
  const user = useSelector((state: ApplicationState) => state.profile.userData);

  const loadProducts = () => {
    setProductsLoading(true);
    certifiqueApi
      .getProducts()
      .then(setProducts)
      .finally(() => setProductsLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    certifiqueApi
      .listCourseInfo(params.id)
      .then((res) => setCourse(res))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [params.id]);

  useEffect(() => {
    if (location.state && location.state.finishedCourse) {
      setPopup(true);
      setFinishedCourse(location.state.finishedCourse);
      loadProducts();
    }
  }, [location.state]);

  useEffect(() => {
    if (user && course) {
      setButtonLoading(true);
      certifiqueApi
        .getUserCourses()
        .then((res) => {
          const fc = res.finished_courses.find(
            (f) => f.course_id === course.id
          );
          if (fc) {
            setFinishedCourse(fc);
            loadProducts();
          }
        })
        .finally(() => {
          setButtonLoading(false);
        });
    }
  }, [course, user]);

  const handlePopupClose = () => {
    setPopup(false);
  };

  const handleAddToCart = (product: Product) => {
    handlePopupClose();
    if (course && finishedCourse) {
      dispatch(
        addToCart({
          courseName: course.name,
          finishedCourse,
          product,
        })
      );

      cart.showCart();
    }
  };

  const convertProductsToOption = () => {
    return products.map((product) => ({
      title: product.name,
      price: product.price,
      onClick: () => handleAddToCart(product),
    }));
  };

  const handleNavigateToQuiz = (c: Course) => {
    if (finishedCourse) {
      if (finishedCourse.paid) {
        history.push(`/certificate?code=${finishedCourse.unique_id}`);
      } else {
        setPopup(true);
      }
    } else if (user) {
      history.push(`/questionario/${c.quiz_id}`, {
        courseId: c.id,
        slug: c.slug,
      });
    } else {
      login.showPopup({ step: 'login' });
    }
  };

  const handleToggleDocs = () => {
    setShowDocs(!showDocs);
  };

  const getButtonText = () => {
    if (buttonLoading) return 'Carregando';
    if (finishedCourse) {
      if (finishedCourse.paid) {
        return 'Baixar certificado';
      }
      return 'Solicitar certificado';
    }
    return user ? 'Questionário' : 'Matricular';
  };

  return (
    <>
      <Helmet title={course && course.name}>
        <link
          rel="canonical"
          href={`https://certifiquecursos.com/course/${course?.slug}`}
        />
        <meta name="description" content={course && course.description} />
      </Helmet>
      {course?.id && (
        <MessageBox isOpen={showDocs} onClose={handleToggleDocs}>
          <LinkContainer>
            {course.docs.map((doc) => (
              <Box m="0 0 .5rem" key={doc.id}>
                <ButtonBase>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    <Paper>
                      <Box
                        p=".5rem"
                        display="flex"
                        justifyContent="space-between"
                        minWidth="30rem"
                        alignItems="center"
                      >
                        <Typography noWrap>{doc.title}</Typography>
                        <CloudDownload />
                      </Box>
                    </Paper>
                  </a>
                </ButtonBase>
              </Box>
            ))}
          </LinkContainer>
        </MessageBox>
      )}
      {quizWithError !== null && quizWithError !== undefined && (
        <MessageBox
          isOpen={!!quizWithError}
          onClose={() => setQuizWithError(undefined)}
        >
          <Box
            p=".5rem"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant="h4" gutterBottom align="center">
              Nota mínima não alcançada!
            </Typography>
            <Typography variant="h5" gutterBottom>
              <strong>Sua nota:</strong> {quizWithError.toFixed()}%
            </Typography>
            <Typography variant="h6" align="justify">
              Mas não se preocupe você pode realizar o questionário novamente!
            </Typography>
          </Box>
        </MessageBox>
      )}
      <Container>
        {!loading ? (
          course?.id && (
            <>
              {finishedCourse && (
                <CourseFinishedPopup
                  onClose={handlePopupClose}
                  isOpen={popup}
                  options={convertProductsToOption()}
                  courseName={course.name}
                  grade={finishedCourse.grade}
                  loading={productsLoading}
                />
              )}
              <InnerContainer>
                <Typography variant="h4" gutterBottom>
                  {course.name}
                  <SubtitleBar />
                </Typography>
                <FreeText variant="h5" gutterBottom>
                  GRÁTIS
                </FreeText>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} lg={4}>
                    <Image
                      src={course.image || placeholder}
                      alt="Foto do curso"
                    />
                    <ButtonsContainer>
                      {user && (
                        <Button
                          disableElevation
                          variant="outlined"
                          color="primary"
                          size="large"
                          onClick={handleToggleDocs}
                        >
                          Baixar Material
                        </Button>
                      )}

                      <Button
                        disabled={buttonLoading}
                        disableElevation
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => handleNavigateToQuiz(course)}
                      >
                        {getButtonText()}
                      </Button>
                    </ButtonsContainer>
                  </Grid>
                  <Grid xs={12} md={6} lg={8}>
                    <Box p="0 3rem 0">
                      <Typography variant="subtitle1" gutterBottom>
                        Informações do curso
                        <SubtitleBar />
                      </Typography>
                      <Typography gutterBottom>
                        {parse(course.description || '')}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Carga horária
                        <SubtitleBar />
                      </Typography>
                      <Typography gutterBottom>
                        {course.duration} horas
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Conteúdo programático
                        <SubtitleBar />
                      </Typography>
                      {course.content.map((e) => (
                        <Typography variant="body1" key={e.item} gutterBottom>
                          {e.item}
                        </Typography>
                      ))}

                      <Typography variant="subtitle1" gutterBottom>
                        Para solicitar o certificado
                        <SubtitleBar />
                      </Typography>

                      <Typography variant="body1">
                        O curso é gratuito e 100% online, são fornecidas
                        apostilas para que o aluno possa estudar seu conteúdo.
                        Quando desejar, o aluno pode realizar o questionário, e,
                        ao atingir uma média superior a 60%, o aluno pode
                        solicitar seu certificado reconhecido em todo território
                        nacional.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </InnerContainer>
            </>
          )
        ) : (
          <Box
            display="flex"
            width="100%"
            height="80vh"
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={70} />
          </Box>
        )}
        {!loading && !course?.id && (
          <Box margin="auto">
            <Paper>
              <Box
                padding="1rem"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Typography variant="h4" gutterBottom>
                  Ops! Infelizmente o curso solicitado não está disponível, mas
                  você ainda pode encontrar vários outros!
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push('/todos-cursos')}
                >
                  Ver cursos
                </Button>
              </Box>
            </Paper>
          </Box>
        )}
        {/* <RelatedCourses>
        <Typography variant="subtitle1" gutterBottom>
          Você também pode gostar
          <SubtitleBar />
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CourseCardContainer>
              <CoursesCard
                title="DRENAGEM LINFATICA"
                subtitle="O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500"
              />
            </CourseCardContainer>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3}>
            <CourseCardContainer>
              <CoursesCard
                title="DRENAGEM LINFATICA"
                subtitle="O Lorem Ipsum é um texto modelo da indústria tipográfica e de impressão. O Lorem Ipsum tem vindo a ser o texto padrão usado por estas indústrias desde o ano de 1500"
              />
            </CourseCardContainer>
          </Grid>
        </Grid>
      </RelatedCourses> */}
      </Container>
    </>
  );
};

export default CoursePage;
