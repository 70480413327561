import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

import certificado from '../../assets/certificado.png';

export const Container = styled(MuiContainer)``;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 30px;

  margin-top: 30px;

  canvas {
    width: 100%;
    height: auto;

    margin-bottom: 2rem;
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export const CanvasContainer = styled.div``;

export const ContainerImage = styled.div`
  background: url(${certificado});
  background-repeat: no-repeat;

  height: 794px;
  width: 1123px;
`;

export const InstitutionSignature = styled.div`
  position: absolute;

  h1 {
    position: relative;
    top: 600px;
    left: 55px;
    font-size: 32px;
    /* bottom: 40px; */
  }
`;

export const StudentName = styled.div`
  position: absolute;

  h1 {
    position: relative;
    top: 320px;
    left: 415px;
    font-size: 45px;
    bottom: 40px;
  }
`;

export const InfoContainer = styled.div`
  @media (max-width: 1024px) {
    margin: 0 auto;

    max-width: 280px;
    grid-row: 1;
  }
`;

export const CertificateContainer = styled.div`
  background: #fff;
  position: absolute;
  width: 100vh;
  height: 100vh;
`;

export const Image = styled.img`
  max-width: 100%;
  height: auto;
`;

export const InfoContent = styled.div`
  margin-top: 2rem;
  background: #f1f1f1;

  color: #444;
  margin: 2em auto;
  padding: 1em 2em;
  max-width: 700px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);

  font-size: 14px;
  line-height: 1.5;
`;
export const InfoCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p:nth-child(2) {
    color: green;
  }
`;
export const InfoName = styled.p``;
export const InfoCourse = styled.p``;
export const InfoHours = styled.p``;
export const InfoMenssage = styled.p`
  margin-top: 20px;
`;
