import React, { useCallback, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Formik, Field, FieldProps, FormikValues } from 'formik';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import * as Yup from 'yup';
import { cpf as cpfValidator } from 'cpf-cnpj-validator';
import { Alert } from '@material-ui/lab';
import { TextInputField } from '../../InputField';
import { Step } from '../types';
import { NavLink } from '../styles';
import certifiqueApi from '../../../services/certifiqueApi';

interface RegisterProps {
  setStep: (step: Step) => void;
  onFinish?: () => void;
}

const phoneRegExp = new RegExp(
  '^((\\+\\d{2}\\s)?\\(\\d{2}\\)\\s?\\d{4}\\d?\\-\\d{4})?$'
);
const dddRegExp = new RegExp('\\([1-9]{1}\\d{1}\\)');
const digitNineRegexExp = new RegExp('\\)\\s?9');

const schema = Yup.object().shape({
  cpf: Yup.string().required('CPF não pode estar vazio'),
  name: Yup.string().required('Nome não pode estar vazio'),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref('email'), undefined], 'Os emails devem ser iguais')
    .required('Confirmação de email é obrigatório'),
  phoneNumber: Yup.string()
    .required('Celular é obrigatório')
    .matches(dddRegExp, 'DDD inválido')
    .matches(digitNineRegexExp, 'Celular inválido, inclua o dígito 9')
    .matches(phoneRegExp, 'Número do celular não é valido'),
  email: Yup.string()
    .email('Insira um email válido')
    .required('Email não pode estar vazio'),
  password: Yup.string()
    .min(6, 'Mínimo de 6 caractéres')
    .required('Confirmação de senha não pode estar vazia'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'As senhas devem ser iguais')
    .required('Confirmação de senha é obrigatório'),
});

const Register: React.FC<RegisterProps> = ({ onFinish, setStep }) => {
  const [cpfError, setCpfError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up('sm'));

  const [alert, setAlert] = useState<
    | {
        severity: 'warning' | 'success' | 'info' | 'error';
        text: string;
      }
    | undefined
  >();

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      let { cpf, phoneNumber } = values;
      const { name, password, email } = values;

      cpf = cpf.replace(/\D/g, '');
      phoneNumber = phoneNumber.replace(/\D/g, '');
      if (!cpfValidator.isValid(cpf)) {
        setCpfError('CPF inválido');
        return;
      }
      setLoading(true);
      certifiqueApi
        .register({
          phone_number: phoneNumber,
          name,
          password,
          email,
          cpf,
        })
        .then(() => {
          if (onFinish) onFinish();
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            setAlert({
              severity: 'warning',
              text:
                err.response.data.message || 'Ocorreu um erro tente novamente',
            });
          } else {
            setAlert({
              severity: 'error',
              text: 'Ocorreu um erro inesperado',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [onFinish]
  );

  function validateRecatcha(value: string | null) {
    if (value) {
      setToken(value);
    }
  }

  return (
    <div>
      <Formik
        initialValues={{
          email: '',
          cpf: '',
          name: '',
          phoneNumber: '',
          password: '',
          passwordConfirmation: '',
          emailConfirmation: '',
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ errors, touched }) => (
          <Form>
            <Typography variant="h6" gutterBottom align="center">
              Registre sua conta
            </Typography>

            <Field name="email">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Email"
                  messageType={
                    (touched.email && errors.email && 'error') || undefined
                  }
                  message={(touched.email && errors.email) || undefined}
                />
              )}
            </Field>
            <Field name="emailConfirmation">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Confirmação de email"
                  messageType={
                    (touched.emailConfirmation &&
                      errors.emailConfirmation &&
                      'error') ||
                    undefined
                  }
                  message={
                    (touched.emailConfirmation && errors.emailConfirmation) ||
                    undefined
                  }
                />
              )}
            </Field>
            <Field name="phoneNumber">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Número de telefone"
                  mask="(99) 99999-9999"
                  messageType={
                    (touched.phoneNumber && errors.phoneNumber && 'error') ||
                    undefined
                  }
                  message={
                    (touched.phoneNumber && errors.phoneNumber) || undefined
                  }
                />
              )}
            </Field>
            <Field name="cpf">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="CPF"
                  messageType={
                    (((touched.cpf && errors.cpf) || cpfError) && 'error') ||
                    undefined
                  }
                  message={(touched.cpf && errors.cpf) || cpfError || undefined}
                  onChange={(e) => {
                    setCpfError('');
                    field.onChange(e);
                  }}
                  mask="999.999.999-99"
                />
              )}
            </Field>
            <Field name="name">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Nome Completo"
                  messageType={
                    (touched.name && errors.name && 'error') || undefined
                  }
                  message={(touched.name && errors.name) || undefined}
                />
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Senha"
                  type="password"
                  messageType={
                    (touched.password && errors.password && 'error') ||
                    undefined
                  }
                  message={(touched.password && errors.password) || undefined}
                />
              )}
            </Field>
            <Field name="passwordConfirmation">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Confirmação de senha"
                  type="password"
                  messageType={
                    (touched.passwordConfirmation &&
                      errors.passwordConfirmation &&
                      'error') ||
                    undefined
                  }
                  message={
                    (touched.passwordConfirmation &&
                      errors.passwordConfirmation) ||
                    undefined
                  }
                />
              )}
            </Field>
            <Box
              display="flex"
              justifyContent="flex-end"
              m="0 0 1rem"
              p="0 1rem 0"
            >
              <NavLink onClick={() => setStep('login')}>Fazer login</NavLink>
            </Box>

            {alert && (
              <Alert severity={alert.severity} style={{ marginBottom: '1rem' }}>
                {alert.text}
              </Alert>
            )}
            <Box display="flex" justifyContent="center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                onChange={validateRecatcha}
                size={mediaQuery ? 'normal' : 'compact'}
              />
            </Box>
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              marginTop="10px"
            >
              <Button
                disabled={!token || loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Registrar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Register;
