import styled from 'styled-components';

export const Container = styled.div`
  padding: 2rem 0;
`;

export const Header = styled.div``;

export const InstructionsContainer = styled.div`
  margin: 2rem 0;
  border: 1px solid #eee;
  padding: 1rem;
  border-radius: 10px;
  background: #efefef;
`;

export const InstructionsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-gap: 1rem;
`;

export const Instruction = styled.li`
  display: grid;
  grid-gap: 1.75em;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.25;
  grid-template-columns: 0 1fr;

  ::before {
    font-family: 'Font Awesome 5 Free';
    content: '\f111';
    font-size: 1rem;
    color: #000;
  }
`;

export const QuestionsContainer = styled.div`
  > div {
    margin-bottom: 3rem;
  }
`;
