import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { ApplicationState } from './types';

export default (reducers: Reducer<ApplicationState>) => {
  const persistedReducer = persistReducer(
    {
      key: 'certifique-cursos',
      storage,
      whitelist: ['profile'],
    },
    reducers
  );

  return persistedReducer;
};
