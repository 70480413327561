import React, { useCallback, useEffect, useState, useRef } from 'react';
import { jsPDF as Pdf } from 'jspdf';
import { subDays, format } from 'date-fns';

import { useSearchParam } from 'react-use';
import { useSelector } from 'react-redux';

import { Alert } from '@material-ui/lab';
import { Box, CircularProgress } from '@material-ui/core';

import canvasTxt from 'canvas-txt';
import { ApplicationState } from '../../store/types';

import certifiqueApi from '../../services/certifiqueApi';

import CertificateBackground from '../../assets/certificado-new.png';
import CertificateBackBackground from '../../assets/certificadoBack-new.jpg';

import Course from '../../common/interfaces/course';
import { FinishedCourse } from '../../common/interfaces/finishedCourse';
import { User } from '../../common/interfaces/user';

import Button from '../../components/Button';
import CoursesCard from '../../components/CoursesCard';
import {
  Container,
  ContentContainer,
  InfoContainer,
  CanvasContainer,
  InfoContent,
  InfoCode,
} from './styles';

export const cpfMask = (value: string) => {
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

const Certificate: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [certificate, setCertificate] = useState<Course>();
  const [finishedCourse, setFinishedCourse] = useState<FinishedCourse>();
  const [userData, setUserData] = useState<User>();
  const [verify, setVerify] = useState(false);
  const [error, setError] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);
  const imgBackRef = useRef<HTMLImageElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasBackRef = useRef<HTMLCanvasElement>(null);
  const code = useSearchParam('code');
  const user = useSelector((state: ApplicationState) => state.profile.userData);

  const genereteImageCertificate = useCallback(() => {
    const canvas = canvasRef.current;
    const img = imgRef.current;

    const canvasBack = canvasBackRef.current;
    const imgBack = imgBackRef.current;

    if (finishedCourse && certificate) {
      if (canvas && img) {
        const ctx = canvas.getContext('2d');
        img.onload = () => {
          if (ctx) {
            ctx.drawImage(img, 0, 0);
            ctx.font = '60px Roboto';
            ctx.fillStyle = 'black';
            ctx.textAlign = 'center';
            ctx.fillText(finishedCourse.student_name, 840, 550, 800);

            ctx.font = '30px Roboto';
            ctx.fillText(
              `Portador(a) do CPF ${cpfMask(
                finishedCourse.user_cpf || user?.cpf || ''
              )},`,
              840,
              600,
              1000
            );
            ctx.fillText(
              `concluiu o curso "${certificate.name}",`,
              840,
              650,
              1000
            );
            ctx.fillText(
              `realizado entre ${format(
                subDays(
                  new Date(`${finishedCourse.finished_date} 12:00`),
                  Math.ceil(finishedCourse.duration / 8)
                ),
                'dd/MM/yyyy'
              )} e ${
                finishedCourse.finished_date
                  ? format(
                      new Date(`${finishedCourse.finished_date} 12:00`),
                      'dd/MM/yyyy'
                    )
                  : '01/01/2020'
              }`,
              840,
              700,
              500
            );
            ctx.fillText(
              `com carga horária de ${finishedCourse.duration} horas`,
              840,
              750,
              800
            );
          }
        };

        if (canvasBack && imgBack) {
          const ctxBack = canvasBack.getContext('2d');

          imgBack.onload = () => {
            if (ctxBack) {
              ctxBack.drawImage(imgBack, 0, 0);
              ctxBack.font = '36px Roboto';
              ctxBack.fillStyle = 'black';
              ctxBack.textAlign = 'center';
              ctxBack.fillText('Conteúdo Programático', 840, 310);

              ctxBack.font = '18px Roboto';
              ctxBack.textAlign = 'left';

              canvasTxt.fontSize = 24;
              canvasTxt.justify = true;
              canvasTxt.lineHeight = 30;

              canvasTxt.drawText(
                ctxBack,
                certificate.content
                  .map((content) => {
                    return ` • ${content.item}`;
                  })
                  .join(),
                60,
                0,
                1550,
                800
              );

              ctxBack.font = '22px Roboto';
              ctxBack.textAlign = 'left';
              ctxBack.fillText(
                'Emitido conforme a Lei Nº. 9394/96, art.67 e 87, Inciso III,',
                80,
                866
              );
              ctxBack.fillText('O Decreto Nº. 5.154/04', 80, 896);
              ctxBack.fillText('Resolução CNE/CEB nº 04/99', 80, 926);

              ctxBack.textAlign = 'right';
              ctxBack.fillText(
                `Código de autenticidade: ${finishedCourse.unique_id}`,
                1600,
                866
              );
              ctxBack.fillText(
                'Valide o certicado em: www.certifiquecursos.com.br/validar-certificado',
                1600,
                896
              );

              ctxBack.textAlign = 'left';
              ctxBack.fillStyle = '#fff';
              ctxBack.fillText(
                'Certifique Cursos CPNJ: 30.149.995/0001-18',
                190,
                1110
              );

              ctxBack.font = '22px Roboto';

              ctxBack.fillText(
                'Certificado válido em todo Território Nacional',
                190,
                1140
              );

              ctxBack.font = '22px Roboto';
              ctxBack.textAlign = 'center';
            }
          };
        }
      }
      setVerify(true);
    }
  }, [certificate, finishedCourse, user?.cpf]);

  function genereteCertificate() {
    const canvas = canvasRef.current;
    const canvasBack = canvasBackRef.current;
    if (canvas && canvasBack) {
      const pdf = new Pdf({
        orientation: 'landscape',
        precision: 2,
      });
      pdf.addImage(canvas, 'PNG', 0, 0, 300, 210);
      pdf.addPage();
      pdf.addImage(canvasBack, 'PNG', 0, 0, 300, 210);
      pdf.save('certificado.pdf');
    }
  }

  const getCertificate = useCallback(async () => {
    setLoading(true);
    if (code) {
      if (user) await certifiqueApi.getUser().then((res) => setUserData(res));

      setError(false);
      await certifiqueApi
        .getCertificate({
          code,
        })
        .then((res) => {
          setCertificate(res.course);
          setFinishedCourse(res.finished_course);
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 404) {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [code, user]);

  useEffect(() => {
    getCertificate();
  }, [getCertificate]);

  useEffect(() => {
    genereteImageCertificate();
  }, [genereteImageCertificate]);

  return (
    <>
      {error && (
        <Container maxWidth="xs">
          <Alert severity="warning">Certificado não encontrado</Alert>
        </Container>
      )}
      {loading && (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
      {user &&
      (finishedCourse?.user_id === user.id ||
        user.email === 'contato@certifiquecursos.com.br') ? (
        <Container>
          {!!certificate && !!userData && (
            <ContentContainer>
              {!verify && (
                <>
                  <img
                    ref={imgRef}
                    src={CertificateBackground}
                    alt="certificado"
                    style={{
                      height: 1190,
                      width: 1682,
                    }}
                  />
                  <img
                    ref={imgBackRef}
                    src={CertificateBackBackground}
                    alt="certificado"
                    style={{
                      height: 1190,
                      width: 1682,
                    }}
                  />
                </>
              )}
              <CanvasContainer>
                <canvas ref={canvasRef} width="1682" height="1190" />
                <canvas ref={canvasBackRef} width="1682" height="1190" />
              </CanvasContainer>

              <InfoContainer>
                {certificate && <CoursesCard title={certificate.name} />}
                <Button
                  className="blue"
                  onClick={genereteCertificate}
                  style={{ marginTop: '10px' }}
                >
                  Baixar
                </Button>
              </InfoContainer>
            </ContentContainer>
          )}
        </Container>
      ) : (
        <Container>
          {!loading && !!certificate && !!finishedCourse && (
            <InfoContent>
              <InfoCode>
                <p>
                  <strong>Certificado:</strong> {code}
                </p>
                <p>Ativo</p>
              </InfoCode>
              {/* <InfoName>Nome: {certificate.name}</InfoName> */}
              <p>
                <strong>Aluno:</strong> {finishedCourse?.student_name}
              </p>
              <p>
                <strong>Curso:</strong> {certificate.name}
              </p>
              <p>
                <strong>Concluído em:</strong>{' '}
                {format(
                  new Date(`${finishedCourse.finished_date} 12:00`),
                  'dd/MM/yyyy'
                )}
              </p>
              <p>
                <strong>Duração:</strong> {finishedCourse.duration} horas
              </p>
              <p>
                Por segurança apenas o usuário logado pode ver este certificado.
              </p>
            </InfoContent>
          )}
        </Container>
      )}
    </>
  );
};

export default Certificate;
