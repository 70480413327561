import { withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const TabsContainer = styled.div`
  display: block;
  margin: 0 auto;
`;

export const Container = withTheme(styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0.5rem;
  border-top: 2px solid #777;
  border-bottom: 2px solid #777;
  grid-gap: 3px;
  margin: 1rem 0;
  a {
    font-weight: 500;
    font-size: 1.6rem;
    color: #777;
    text-align: center;

    &.tab-active {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  ${({ theme }) => theme.breakpoints.up('md')} {
    font-size: 2rem;
    padding: 1rem;
  }
`);
