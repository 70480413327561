import React, { useCallback, useState } from 'react';
import { Form, Formik, Field, FieldProps, FormikValues } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { cpf } from 'cpf-cnpj-validator';
import * as Yup from 'yup';
import { Alert } from '@material-ui/lab';
import { TextInputField } from '../../../components/InputField';
import { Container, Section } from './styles';
import { ApplicationState } from '../../../store/types';
import certifiqueApi from '../../../services/certifiqueApi';
import { saveUserSuccess } from '../../../store/modules/profile/action';

const phoneRegExp = new RegExp(
  '^((\\+\\d{2}\\s)?\\(\\d{2}\\)\\s?\\d{4}\\d?\\-\\d{4})?$'
);
const dddRegExp = new RegExp('\\([1-9]{1}\\d{1}\\)');
const digitNineRegexExp = new RegExp('\\)\\s?9');

const schema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Celular é obrigatório')
    .matches(dddRegExp, 'DDD inválido')
    .matches(digitNineRegexExp, 'Celular inválido, inclua o dígito 9')
    .matches(phoneRegExp, 'Número do celular não é valido'),
});

const MyProfile: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.profile.userData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{
    text: string;
    type?: 'error' | 'warning' | 'success' | undefined;
  }>({
    text: '',
  });

  const handleSubmit = useCallback(
    (e: FormikValues) => {
      setLoading(true);

      certifiqueApi
        .updateUser({
          name: e.name,
          password: e.password,
          phone_number: e.phoneNumber,
        })
        .then((res) => {
          dispatch(saveUserSuccess(res));
          setMessage({
            text: 'Cadastro alterado com sucesso',
            type: 'success',
          });
        })
        .catch(() => {
          setMessage({
            text: 'Ocorreu um erro, tente novamente',
            type: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  const customValidation = useCallback((values: FormikValues) => {
    const errors: FormikValues = {} as FormikValues;

    if (!values.name) {
      errors.name = 'Nome não pode estar vazio';
    }

    if (values.password && !values.passwordConfirmation) {
      errors.passwordConfirmation =
        'Confirmação de senha é obrigatório ao alterar senha';
    } else if (
      values.password &&
      values.passwordConfirmation !== values.password
    ) {
      errors.passwordConfirmation = 'As senhas não coincidem';
    }
    return errors;
  }, []);

  return user ? (
    <Container>
      <Formik
        initialValues={{
          email: user?.email || '',
          name: user?.name || '',
          cpf: user?.cpf || '',
          phoneNumber: user?.phone_number || '',
          password: '',
          passwordConfirmation: '',
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        validate={customValidation}
      >
        {({ touched, errors }) => (
          <Form>
            <Section>
              <Typography variant="h6" gutterBottom color="primary">
                Dados pessoais
              </Typography>
              <TextInputField label="Email" value={user.email} disabled />
              <TextInputField
                label="CPF"
                value={cpf.format(user.cpf || '')}
                disabled
              />
              <Field name="name">
                {({ field }: FieldProps) => (
                  <TextInputField {...field} label="Nome" />
                )}
              </Field>
              <Field name="phoneNumber">
                {({ field }: FieldProps) => (
                  <TextInputField
                    {...field}
                    label="Celular"
                    mask="(99) 99999-9999"
                    message={(touched.phoneNumber && errors.phoneNumber) || ''}
                    messageType={
                      (touched.phoneNumber && errors.phoneNumber && 'error') ||
                      undefined
                    }
                  />
                )}
              </Field>
            </Section>
            {/* <Section>
              <Typography variant="h6" gutterBottom>
                Endereço
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Field name="cep">
                    {({ field }: FieldProps) => (
                      <TextInputField {...field} label="CEP" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field name="uf">
                    {({ field }: FieldProps) => (
                      <TextInputField {...field} label="UF" />
                    )}
                  </Field>
                </Grid>
              </Grid>
              <Field name="city">
                {({ field }: FieldProps) => (
                  <TextInputField {...field} label="Cidade" />
                )}
              </Field>
              <Field name="district">
                {({ field }: FieldProps) => (
                  <TextInputField {...field} label="Bairro" />
                )}
              </Field>
              <Field name="street">
                {({ field }: FieldProps) => (
                  <TextInputField {...field} label="Rua" />
                )}
              </Field>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Field name="number">
                    {({ field }: FieldProps) => (
                      <TextInputField {...field} label="Número" />
                    )}
                  </Field>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Field name="complement">
                    {({ field }: FieldProps) => (
                      <TextInputField {...field} label="Complemento" />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Section> */}
            <Section>
              <Typography variant="h6" gutterBottom color="primary">
                Alteração de senha (opcional)
              </Typography>
              <Field name="password">
                {({ field }: FieldProps) => (
                  <TextInputField
                    {...field}
                    label="Nova Senha"
                    type="password"
                  />
                )}
              </Field>
              <Field name="confirmPassword">
                {({ field }: FieldProps) => (
                  <TextInputField
                    {...field}
                    label="Confirmação de senha"
                    type="passwordConfirmation"
                  />
                )}
              </Field>
            </Section>
            <Box
              display="flex"
              flexDirection="row"
              width="100%"
              justifyContent="center"
              marginBottom="1rem"
            >
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                color="primary"
              >
                Salvar
              </Button>
            </Box>
            {message.text && (
              <Alert severity={message.type || 'info'}>{message.text}</Alert>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  ) : (
    <Redirect to="/" />
  );
};

export default MyProfile;
