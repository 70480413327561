import React from 'react';
import { NavLink, Link, useRouteMatch } from 'react-router-dom';

import { Container } from './styles';

const TabBar: React.FC = () => {
  const { url } = useRouteMatch();
  return (
    <Container>
      <NavLink activeClassName="tab-active" to={`${url}/cursos`}>
        Meus Cursos
      </NavLink>
      <NavLink activeClassName="tab-active" to={`${url}/pagamentos`}>
        Pagamentos
      </NavLink>
      <NavLink activeClassName="tab-active" to={`${url}/alterar-dados`}>
        Alterar dados
      </NavLink>
    </Container>
  );
};

export default TabBar;
