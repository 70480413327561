import { createMuiTheme } from '@material-ui/core';

export default createMuiTheme({
  palette: {
    primary: {
      main: '#2C3058',
    },
  },
  typography: {
    // Diz ao Material-UI qual é o font-size no elemento html.
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    htmlFontSize: 10,
    h1: {
      fontSize: '4.8rem',
      fontWeight: 500,
      fontFamily: 'Montserrat, Roboto, sans-serif',
    },
    h2: {
      fontSize: '4rem',
      fontWeight: 500,
      fontFamily: 'Montserrat, Roboto, sans-serif',
    },
    h3: {
      fontSize: '3.6rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '2.4rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.8rem',
      fontWeight: 400,
    },
    h6: {
      fontSize: '1.6rem',
      fontWeight: 400,
    },
    subtitle1: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '1.6rem',
      fontWeight: 400,
    },
    body1: {
      fontSize: '1.4rem',
    },
    body2: {
      fontSize: '1.6rem',
    },
    caption: {
      fontSize: '1.2rem',
      fontWeight: 300,
    },
    overline: {
      fontSize: '1.2rem',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
      },
    },
  },
});
