import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useCart from '../../../common/hook/useCart';
import Course from '../../../common/interfaces/course';
import { FinishedCourse } from '../../../common/interfaces/finishedCourse';
import { Product } from '../../../common/interfaces/product';
import CourseFinishedPopup from '../../../components/CourseFinishedPopup';
import CoursesCard from '../../../components/CoursesCard';
import NoResults from '../../../components/NoResults';
import certifiqueApi from '../../../services/certifiqueApi';
import { addToCart } from '../../../store/modules/cart/action';

import { Container } from './styles';

const alt = new Array(4);
alt.fill(Math.random);

const MyCourses: React.FC = () => {
  const history = useHistory();

  const [courses, setCourses] = useState<Course[]>([]);
  const [finishedCourses, setFinishedCourses] = useState<FinishedCourse[]>([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [selectedFinishedCourse, setSelectedFinishedCourse] = useState<
    FinishedCourse
  >();
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const cart = useCart();

  useEffect(() => {
    setLoading(true);
    certifiqueApi
      .getUserCourses()
      .then((res) => {
        const { courses: foundCourses, finished_courses } = res;

        setCourses(foundCourses);
        setFinishedCourses(finished_courses);
      })
      .finally(() => {
        setLoading(false);
      });

    setProductsLoading(true);
    certifiqueApi
      .getProducts()
      .then(setProducts)
      .finally(() => setProductsLoading(false));
  }, []);

  const handleAddToCart = (product: Product) => {
    if (selectedFinishedCourse) {
      const foundCourse = courses.find(
        (course) => course.id === selectedFinishedCourse.course_id
      );
      if (foundCourse) {
        dispatch(
          addToCart({
            courseName: foundCourse.name,
            finishedCourse: selectedFinishedCourse,
            product,
          })
        );

        cart.showCart();
        setSelectedFinishedCourse(undefined);
      }
    }
  };

  const convertProductsToOption = () => {
    return products.map((product) => ({
      title: product.name,
      price: product.price,
      onClick: () => handleAddToCart(product),
    }));
  };

  const handlePopupClose = () => {
    setSelectedFinishedCourse(undefined);
  };

  const handleSelectCourseToBuy = (courseId: number) => {
    setSelectedFinishedCourse(
      finishedCourses.find((e) => e.course_id === courseId)
    );
  };

  return (
    <>
      {!!selectedFinishedCourse && (
        <CourseFinishedPopup
          onClose={handlePopupClose}
          isOpen={!!selectedFinishedCourse}
          options={convertProductsToOption()}
          courseName={
            courses.find(
              (course) => course.id === selectedFinishedCourse.course_id
            )?.name || ''
          }
          grade={selectedFinishedCourse.grade}
          loading={productsLoading}
        />
      )}
      <Container>
        {/* <Grid
          container
          direction="row-reverse"
          className={classes.searchBarContainer}
          justify={upMd ? undefined : 'center'}
        >
          <Grid item xs={6} md={6} lg={3}>
            <SearchBar />
          </Grid>
        </Grid> */}
        <Grid container spacing={2}>
          {!loading
            ? courses.map((course) => (
                <Grid key={course.id} item xs={12} md={6} lg={3}>
                  <CoursesCard
                    title={course.name}
                    image={course.image}
                    buttonProps={{
                      variant: finishedCourses.some(
                        (e) => e.course_id === course.id && e.paid
                      )
                        ? 'outlined'
                        : 'contained',
                      color: 'primary',
                      onClick: finishedCourses.some(
                        (e) => e.course_id === course.id && e.paid
                      )
                        ? () => {
                            const code = finishedCourses.find(
                              (e) => e.course_id === course.id && e.unique_id
                            );
                            if (code) {
                              history.push(
                                `/certificate?code=${code.unique_id}`
                              );
                            }
                          }
                        : () => handleSelectCourseToBuy(course.id),
                    }}
                    buttonText={
                      finishedCourses.some(
                        (e) => e.course_id === course.id && e.paid
                      )
                        ? 'Baixar certificado'
                        : 'Solicitar Certificado'
                    }
                  />
                </Grid>
              ))
            : alt.map((e) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={e()}>
                  <Skeleton variant="rect" width={295} height={295} />
                </Grid>
              ))}

          {!loading && !courses.length && <NoResults />}
        </Grid>
      </Container>
    </>
  );
};

export default MyCourses;
