import React, { lazy, Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import CoursePage from '../pages/Course';
import AllCourses from '../pages/AllCourses';
import Home from '../pages/Home';
import MyAccount from '../pages/MyAccount';
import QuizPage from '../pages/Quiz';
import AboutUs from '../pages/AboutUs';
import CertificateOnline from '../pages/CertificateOnline';
import CommonQuestions from '../pages/CommonQuestions';
import Certificate from '../pages/Certificate';
import SearchCertificate from '../pages/SearchCertificate';

import Route from './Route';

export default function Routes() {
  // useTracking();
  return (
    <Switch>
      <Route path="/course/:id" exact component={CoursePage} />
      <Route path="/todos-cursos" component={AllCourses} />
      <Route path="/questionario/:id" exact component={QuizPage} />
      <Route path="/minha-conta" component={MyAccount} userOnly />
      <Route path="/quem-somos" component={AboutUs} />
      <Route path="/perguntas-frequentes" component={CommonQuestions} />
      <Route path="/certificado" component={CertificateOnline} />
      <Route path="/certificate" component={Certificate} />
      <Route path="/validar-certificado" component={SearchCertificate} />
      <Route path="/" exact component={Home} />
      <Redirect to="/" />
    </Switch>
  );
}
