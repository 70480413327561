import React from 'react';
import { Link } from 'react-router-dom';
import { Link as MaterialLink } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Logo from '../../assets/Logo.svg';
import ABED from '../../assets/ABED.svg';

import { Container, Content, NavContainer, Credits } from './styles';

const Footer: React.FC = () => {
  return (
    <>
      <Container>
        <Grid container spacing={2} justify="center">
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Content>
              <Typography variant="h5" component="h5">
                QUEM SOMOS
              </Typography>
              <Typography variant="body2">
                A Certifique Cursos é uma escola online, especializada em cursos
                classificados como “livres”. Empresa associada a ABED;
              </Typography>
              <img src={Logo} alt="Certifiquese" style={{ fill: '#fff' }} />
            </Content>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Content>
              <Typography variant="h5" component="h5">
                PÁGINAS
              </Typography>
              <NavContainer>
                <Link to="/">Home</Link>
                <Link to="/todos-cursos">Cursos</Link>
                <Link to="/certificado">Certificado</Link>
                <Link to="/quem-somos">Quem Somos</Link>
                {/* <Link to="/contato">Contato</Link> */}
              </NavContainer>
            </Content>
          </Grid>
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Content>
              <Typography variant="h5" component="h5">
                CONTATO
              </Typography>
              <NavContainer>
                <MaterialLink
                  href="https://api.whatsapp.com/send?phone=5535988660200"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                >
                  (35) 98866-0200
                </MaterialLink>
                <MaterialLink
                  href="mailto:contato@certifiquecursos.com.br"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                >
                  contato@certifique.com.br
                </MaterialLink>
              </NavContainer>
            </Content>
          </Grid>
          {/* <Grid item xs={6} sm={4} md={3} lg={2}>
            <Content>
              <h1>REDES SOCIAIS</h1>
              <FacebookIcon />
              <InstagramIcon />
              <TwitterIcon />

            </Content>
          </Grid> */}

          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Content>
              <img src={ABED} alt="Certifiquese" style={{ fill: '#fff' }} />
            </Content>
          </Grid>
        </Grid>
      </Container>
      {/* <Credits>
        Feito por
        <MaterialLink
          href="https://piblab.com"
          target="_blank"
          color="secondary"
          rel="noreferrer"
        >
          PiBytesLab
        </MaterialLink>
      </Credits> */}
    </>
  );
};

export default Footer;
