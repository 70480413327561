import styled from 'styled-components';
import { Container as MuiContainer, withTheme } from '@material-ui/core';
import HomeBackground from '../../assets/HomeBackground.svg';
import MobileBackground from '../../assets/students.jpg';

export const Container = styled(MuiContainer)`
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: flex-start;

  background: url(${HomeBackground});
  background-position-x: 35vw;
  background-position-y: -30vh;
  background-repeat: no-repeat;
  background-size: 70rem;
  position: relative;

  @media (max-width: 600px) {
    &::before {
      background-color: rgba(255, 255, 255, 0.75);
      content: '';
      display: block;
      height: 30rem;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 0;
    }
    background: url(${MobileBackground}), #fff;
    background-position-x: 0;
    background-position-y: 0;
    background-repeat: no-repeat;
    background-size: 100% 30rem;
  }
`;

export const ContentContainer = styled.section`
  h1 {
    margin-bottom: 4rem;
  }
  margin-bottom: 10rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  max-width: 560px;
  position: relative;
  z-index: 1;
  h2 {
    margin-top: 10rem;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    /*background: url(${MobileBackground});
    background-repeat: no-repeat; */

    text-align: center;

    h2 {
      font-size: 21px;
      margin-top: 5rem;
    }

    h1 {
      font-size: 2.4rem;
      margin-bottom: 7px;
    }

    button {
      margin-bottom: 6rem;
      font-size: 15px;
      height: 4rem;
    }
  }
`;

export const CategoryContainer = styled.div`
  margin-top: 2rem;
  max-width: 73.6rem;
`;

export const CoursesSection = styled.section`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;

  h2 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
`;

export const Highlight = withTheme(styled.span`
  color: ${({ theme }) => theme.palette.secondary.main};
`);
