import { IconButton, Modal as MuiModal } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 10px;
  position: relative;
  padding: 2rem;
  .MuiIconButton-root {
    padding: 0.25rem;
  }
  margin: auto;
`;

export const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0.17rem;
  top: 0.17rem;
`;

export const Modal = styled(MuiModal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
