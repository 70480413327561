import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import MuiContainer from '@material-ui/core/Container';
import { Form, Formik, FormikValues } from 'formik';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';
import Question, { QuestionSkeleton } from '../../components/Question';

import {
  Container,
  Header,
  InstructionsContainer,
  InstructionsList,
  Instruction,
  QuestionsContainer,
} from './styles';
import certifiqueApi from '../../services/certifiqueApi';
import Quiz from '../../common/interfaces/quiz';
import { AnswerSheet } from '../../common/interfaces/answerSheet';
import MessageBox from '../../components/MessageBox';

const alt = new Array(3);
alt.fill(Math.random);

const QuizPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const location = useLocation<{ courseId?: string; slug?: string }>();
  const history = useHistory();
  const [quiz, setQuiz] = useState<Quiz>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    setLoading(true);
    certifiqueApi
      .getQuiz(params.id)
      .then((res) => setQuiz(res))
      .finally(() => {
        setLoading(false);
      });
  }, [params.id]);

  const handleSubmit = useCallback(
    (values: FormikValues) => {
      const answerSheet: AnswerSheet[] = [];

      let hasEmpty = false;

      Object.keys(values).forEach((key) => {
        if (!values[key].length) {
          hasEmpty = true;
        }
        answerSheet.push({
          question_id: key,
          answers: values[key],
        });
      });

      if (hasEmpty) {
        setError('Por favor responda todas as questões!');
        return;
      }

      if (quiz && location.state.courseId && location.state.slug) {
        setLoading(true);
        certifiqueApi
          .validateQuiz(answerSheet, quiz.id, location.state.courseId)
          .then((res) => {
            history.push(`/course/${location.state.slug}`, {
              finishedCourse: res,
            });
          })
          .catch((err) => {
            if (err && err.response && err.response.status === 406) {
              history.push(`/course/${location.state.slug}`, {
                quizWithError: err.response.data.data.grade,
              });
              return;
            }

            setError(
              'Ocorreu um erro inesperado, por favor, contate o suporte'
            );
          })
          .finally(() => setLoading(false));
      }
    },
    [history, location.state, quiz]
  );

  const getInitialValues = useCallback(() => {
    const result: any = {};
    if (quiz)
      quiz.questions.forEach((e) => {
        result[e.id] = [];
      });
    return result;
  }, [quiz]);

  return location.state && location.state.courseId ? (
    <>
      <MessageBox isOpen={!!error} onClose={() => setError('')}>
        <Typography variant="h6">{error}</Typography>
      </MessageBox>
      <MuiContainer maxWidth="sm">
        <Container>
          <Header>
            <Typography variant="h4" align="center" gutterBottom>
              Questionário
            </Typography>
            <InstructionsContainer>
              <Typography variant="h5" gutterBottom>
                Instruções:
              </Typography>
              <InstructionsList>
                <Instruction data-icon=">">
                  Responda à todas a questões abaixo
                </Instruction>
                <Instruction>
                  Para ser aprovado é necessário acertar no mínimo 60% das
                  questões
                </Instruction>
                <Instruction>Não há limite de tentativas</Instruction>
                <Instruction>
                  Depois de finalizar com uma nota acima de 60%, seu certificado
                  ficará disponível para compra
                </Instruction>

                <Instruction>
                  Use o material disponível para download para estudos e boa
                  sorte!
                </Instruction>
              </InstructionsList>
            </InstructionsContainer>
          </Header>

          <Formik initialValues={getInitialValues()} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                <QuestionsContainer>
                  {quiz
                    ? quiz.questions.map((question) => (
                        <Question
                          key={question.id}
                          options={question.options}
                          title={question.title}
                          type={question.type as any}
                          description={question.description}
                          onChange={(value) => {
                            setFieldValue(String(question.id), value);
                          }}
                          value={values[String(question.id) as any]}
                        />
                      ))
                    : alt.map((e) => <QuestionSkeleton key={e()} />)}
                </QuestionsContainer>
                <Box width="100%" display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    Enviar questionário
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      </MuiContainer>
    </>
  ) : (
    <Redirect to="/todos-cursos" />
  );
};

export default QuizPage;
