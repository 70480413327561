import { User } from '../../../common/interfaces/user';
import { UserTypes } from './types';

export function saveUserSuccess(user: User) {
  return {
    type: UserTypes.LOGIN_SUCCESS,
    payload: { user },
  };
}

export function logoutSuccess() {
  return {
    type: UserTypes.LOGOUT_SUCCESS,
  };
}
