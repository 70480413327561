import React, { useCallback, useEffect, useState } from 'react';

import ReCAPTCHA from 'react-google-recaptcha';
import { Form, Formik, Field, FieldProps, FormikValues } from 'formik';
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { TextInputField } from '../../InputField';
import certifiqueApi from '../../../services/certifiqueApi';
import { saveUserSuccess } from '../../../store/modules/profile/action';
import { NavLink } from '../styles';
import { Step } from '../types';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido')
    .required('Email é obrigatório'),
  password: Yup.string().required('Senha é obrigatório'),
});

interface LoginProps {
  onFinish?: () => void;
  alert?: {
    severity: 'warning' | 'success' | 'info' | 'error';
    text: string;
  };
  setStep: (step: Step) => void;
}

const Login: React.FC<LoginProps> = ({
  onFinish,
  alert: receivedAlert,
  setStep,
}) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const theme = useTheme();
  const mediaQuery = useMediaQuery(theme.breakpoints.up('sm'));
  const [alert, setAlert] = useState<
    | {
        severity: 'warning' | 'success' | 'info' | 'error';
        text: string;
      }
    | undefined
  >(receivedAlert);

  useEffect(() => {
    if (receivedAlert) setAlert(receivedAlert);
  }, [receivedAlert]);

  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    (values: FormikValues) => {
      setLoading(true);

      certifiqueApi
        .login(values.email, values.password)
        .then((res) => {
          dispatch(saveUserSuccess(res.user));
          if (onFinish) onFinish();
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 403) {
            setAlert({
              severity: 'error',
              text: 'Email ou senha inválidos tente novamente',
            });
          } else {
            setAlert({
              severity: 'error',
              text: 'Ocorreu um erro inesperado tente novamente',
            });
          }
        })
        .finally(() => setLoading(false));
    },
    [dispatch, onFinish]
  );

  function validateRecatcha(value: string | null) {
    if (value) {
      setToken(value);
    }
  }

  return (
    <div>
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
        validationSchema={schema}
      >
        {({ touched, errors }) => (
          <Form>
            <Typography variant="h6" gutterBottom align="center">
              Faça seu login para conseguir seu certificado!
            </Typography>

            <Field name="email">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Email"
                  message={(touched.email && errors.email) || ''}
                  messageType={
                    (touched.email && errors.email && 'error') || undefined
                  }
                />
              )}
            </Field>
            <Field name="password">
              {({ field }: FieldProps) => (
                <TextInputField
                  {...field}
                  label="Senha"
                  message={(touched.password && errors.password) || ''}
                  messageType={
                    (touched.password && errors.password && 'error') ||
                    undefined
                  }
                  type="password"
                />
              )}
            </Field>

            <Box
              display="flex"
              justifyContent="space-between"
              m="0 0 1rem"
              p="0 1rem 0"
            >
              <NavLink onClick={() => setStep('recover')}>
                Recuperar Senha
              </NavLink>
              <NavLink onClick={() => setStep('register')}>Cadastrar</NavLink>
            </Box>

            {alert && (
              <Alert severity={alert.severity} style={{ marginBottom: '1rem' }}>
                {alert.text}
              </Alert>
            )}
            <Box display="flex" justifyContent="center">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
                onChange={validateRecatcha}
                size={mediaQuery ? 'normal' : 'compact'}
              />
            </Box>

            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              marginTop="10px"
            >
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!token || loading}
              >
                Entrar
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
