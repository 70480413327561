import Axios, {
  AxiosPromise,
  AxiosRequestConfig,
  CancelTokenSource,
} from 'axios';

export default class CancelableRequest {
  private call?: CancelTokenSource;

  once = (config: AxiosRequestConfig): AxiosRequestConfig => {
    if (this.call) {
      this.call.cancel('Only one request allowed at a time.');
    }
    this.call = Axios.CancelToken.source();

    config.cancelToken = this.call.token;
    return config;
  };
}
