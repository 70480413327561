import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import { useHistory, useParams, Link } from 'react-router-dom';
import { PaginationItem, Skeleton } from '@material-ui/lab';
import { useDebounce, useSearchParam } from 'react-use';
import SearchBar from '../../components/SearchBar';
import CoursesCard from '../../components/CoursesCard';

import {
  Container,
  SearchContainer,
  CoursesSection,
  PaginationContainer,
  FilterContainer,
} from './styles';
import certifiqueApi from '../../services/certifiqueApi';
import Course from '../../common/interfaces/course';
import NoResults from '../../components/NoResults';
import CoursesFilter from '../../components/CoursesFilter';
import { Taxonomy } from '../../common/interfaces/taxonomy';

const alt = new Array(8);
alt.fill(Math.random);
const AllCourses: React.FC = () => {
  const categoryId = useSearchParam('category');
  const page = Number(useSearchParam('page'));
  const params = useParams<{ category?: string }>();
  // const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [query, setQuery] = useState('');
  const [courses, setCourses] = useState<Course[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<Taxonomy[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(
    categoryId ? Number(categoryId) : 0
  );

  const history = useHistory();

  const getCourses = useCallback(async () => {
    setLoading(true);

    await certifiqueApi
      .listAllCourses({
        _page: page,
        q: query,
        categories: selectedCategoryId || undefined,
        _limit: window.location.host.includes('certifiquecursos') ? 12 : 300,
      })
      .then((res) => {
        const { content, total_pages } = res;
        setCourses(content);
        setTotalPages(total_pages);
      })
      .finally(() => setLoading(false));
  }, [page, query, selectedCategoryId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  const getCategories = useCallback(async () => {
    certifiqueApi.getCourseCategory().then((res) => setCategories(res));
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  // const handlePageChange = useCallback((p: number) => {
  //   setPage(p);
  // }, []);

  useDebounce(
    () => {
      setQuery(search);
    },
    1000,
    [search]
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: number
  ) => {
    setSelectedCategoryId(id);
  };

  const getCategoryName = () => {
    if (!selectedCategoryId || !categories.length) {
      return 'Todos os Cursos';
    }

    return `Cursos - ${
      categories.find((e) => e.term_id === selectedCategoryId)?.name
    }`;
  };

  return (
    <Container>
      <SearchContainer>
        <Typography variant="h2" align="center">
          {getCategoryName()}
        </Typography>
        <FilterContainer>
          <SearchBar onChange={handleSearchChange} />
          <CoursesFilter
            data={categories}
            selectedItem={selectedCategoryId}
            onListItemClick={handleListItemClick}
          />
        </FilterContainer>
      </SearchContainer>
      <Divider />
      <CoursesSection>
        <Grid container spacing={2} alignItems="center">
          {!loading
            ? courses.map((course) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
                  <CoursesCard
                    title={course.name}
                    subtitle={`${course.duration} horas`}
                    image={course.image}
                    onClickCard={() => history.push(`/course/${course.slug}`)}
                    buttonText="Ver curso"
                    buttonProps={
                      {
                        variant: 'contained',
                        color: 'primary',
                        to: `/course/${course.slug}` as any,
                        component: Link as any,
                      } as any
                    }
                  />
                </Grid>
              ))
            : alt.map((e) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={e()}>
                  <Skeleton variant="rect" width={295} height={295} />
                </Grid>
              ))}

          {!loading && !courses.length && <NoResults />}
        </Grid>
      </CoursesSection>
      <PaginationContainer>
        {!!totalPages && (
          <Pagination
            count={totalPages}
            variant="outlined"
            color="primary"
            hidePrevButton
            hideNextButton
            size="large"
            // onChange={(_, p) => handlePageChange(p)}
            renderItem={(item) => (
              <PaginationItem
                component={Link}
                to={`/todos-cursos?page=${item.page}`}
                {...item}
              />
            )}
          />
        )}
      </PaginationContainer>
    </Container>
  );
};

export default AllCourses;
