import produce from 'immer';
import { UserActionsTypes, ProfileState, UserTypes } from './types';

export const INITIAL_STATE: ProfileState = {
  userData: null,
};

export default function user(
  state = INITIAL_STATE,
  action: UserActionsTypes
): ProfileState {
  return produce(state, (draft) => {
    switch (action.type) {
      case UserTypes.LOGIN_SUCCESS: {
        draft.userData = action.payload.user;
        break;
      }
      case UserTypes.LOGOUT_SUCCESS: {
        draft.userData = null;
        break;
      }
      default: {
        break;
      }
    }
  });
}
