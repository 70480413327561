import { Clear } from '@material-ui/icons';
import React from 'react';

import { Container, Modal, CloseButton } from './styles';

interface MessageBoxProps {
  isOpen: boolean;
  onClose: (event: React.SyntheticEvent<{}, Event>) => void;
}

const MessageBox: React.FC<MessageBoxProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  return (
    <Modal open={isOpen || false} onBackdropClick={onClose}>
      <Container>
        <CloseButton onClick={onClose}>
          <Clear fontSize="small" />
        </CloseButton>
        {children}
      </Container>
    </Modal>
  );
};

export default MessageBox;
