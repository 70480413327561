import styled from 'styled-components';
import { Container as MuiContainer } from '@material-ui/core';

export const Container = styled(MuiContainer)`
  h1 {
    margin-top: 4rem;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 4rem;
  margin-bottom: 5rem;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  margin-left: 3rem;

  p {
    margin-bottom: 2rem;
  }

  @media (max-width: 1000px) {
    margin-left: 0;

    p {
      margin-top: 2rem;
    }
  }
`;

export const Image = styled.img`
  max-width: 50%;

  @media (max-width: 1000px) {
    max-width: 70%;
  }

  @media (max-width: 425px) {
    max-width: 100%;
  }
`;
