import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
// import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router-dom';
import {
  AccountCircle,
  ExitToApp,
  Person,
  PersonAdd,
} from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store/types';
import { logoutSuccess } from '../../store/modules/profile/action';
import { clearToken } from '../../services/token';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

interface Link {
  title: string;
  to: string;
}

interface DrawerToolbarProps {
  links: Link[];
  loginAction: () => void;
  registerAction: () => void;
}

const DrawerToolbar: React.FC<DrawerToolbarProps> = ({
  links,
  loginAction,
  registerAction,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector((state: ApplicationState) => state.profile.userData);

  const [isOpen, setIsOpen] = React.useState(false);

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setIsOpen(open);
  };

  const handleNavigate = (link: Link) => {
    history.push(link.to);
  };

  const handleLogout = useCallback(() => {
    dispatch(logoutSuccess());
    clearToken();
    history.push('/');
  }, [dispatch, history]);

  return (
    <>
      <MenuIcon
        style={{
          fill: '#fff',
          marginLeft: '1rem',
        }}
        onClick={toggleDrawer(true)}
      />
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
        <div
          className={clsx(classes.list)}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {links.map((link) => (
              <ListItem
                button
                key={link.title}
                onClick={() => handleNavigate(link)}
              >
                <ListItemText primary={link.title} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            <ListItem button onClick={loginAction}>
              <ListItemIcon>
                {user ? <AccountCircle /> : <Person />}
              </ListItemIcon>
              <ListItemText primary={user ? 'Minha Conta' : 'Login'} />
            </ListItem>
            <ListItem button onClick={user ? handleLogout : registerAction}>
              <ListItemIcon>
                {user ? <ExitToApp /> : <PersonAdd />}
              </ListItemIcon>
              <ListItemText primary={user ? 'Sair' : 'Cadastrar'} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default DrawerToolbar;
