import React, { useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import SearchIcon from '@material-ui/icons/Search';
import { IconButton } from '@material-ui/core';
import WindowDimensions from '../../common/hook/WindowDimensions';

import DrawerContainer from '../DrawerToolbar';
import Button from '../Button';
import Logo from '../../assets/Logo.svg';

import { Wrapper, Container } from './styles';
import useLogin from '../../common/hook/useLogin';
import { ApplicationState } from '../../store/types';
import { logoutSuccess } from '../../store/modules/profile/action';
import { clearToken } from '../../services/token';

const links = [
  {
    title: 'Home',
    to: '/',
  },
  {
    title: 'Cursos',
    to: '/todos-cursos',
  },
  {
    title: 'Certificado',
    to: '/certificado',
  },
  {
    title: 'Quem Somos',
    to: '/quem-somos',
  },
  {
    title: 'Perguntas frequentes',
    to: '/perguntas-frequentes',
  },
  {
    title: 'Validar Cerificado',
    to: '/validar-certificado',
  },
  // {
  //   title: 'Contato',
  //   to: '/',
  // },
];

const Header: React.FC = () => {
  const { windowWidth } = WindowDimensions();
  const { showPopup } = useLogin();
  const history = useHistory();
  const dispatch = useDispatch();

  const profile = useSelector((state: ApplicationState) => state.profile);

  const handleOpenRegister = useCallback(
    (step: 'register' | 'login') => {
      showPopup({ step });
    },
    [showPopup]
  );

  const handleLogout = useCallback(() => {
    dispatch(logoutSuccess());
    clearToken();
    history.push('/');
  }, [dispatch, history]);

  return (
    <Wrapper>
      {windowWidth <= 1023 && (
        <DrawerContainer
          links={links}
          loginAction={() =>
            profile.userData
              ? history.push('/minha-conta/cursos')
              : handleOpenRegister('login')
          }
          registerAction={() =>
            profile.userData
              ? history.push('/minha-conta/cursos')
              : handleOpenRegister('register')
          }
        />
      )}
      <Container>
        <Link to="/">
          <img
            src={Logo}
            alt="Certifiquese"
            style={{ fill: '#fff', height: 30 }}
          />
        </Link>
        {windowWidth >= 900 && (
          <>
            <nav>
              <Link className="link" to="/">
                Home
              </Link>
              <Link className="link" to="/todos-cursos">
                Cursos
              </Link>
              <Link className="link" to="/certificado">
                Certificado
              </Link>
              <Link className="link" to="/quem-somos">
                Quem Somos
              </Link>
              <Link className="link" to="/perguntas-frequentes">
                Perguntas frequentes
              </Link>
              <Link className="link" to="/validar-certificado">
                Validar certificado
              </Link>

              <IconButton>
                <SearchIcon
                  onClick={() => history.push('/todos-cursos')}
                  style={{
                    color: '#fff',
                  }}
                />
              </IconButton>
              {/* <Link className="link" to="/">
                Contato
              </Link> */}
            </nav>
            <nav>
              {profile.userData ? (
                <>
                  <Button
                    onClick={handleLogout}
                    outlined
                    style={{ marginRight: '1rem' }}
                  >
                    Sair
                  </Button>
                  <Button onClick={() => history.push('/minha-conta/cursos')}>
                    Minha Conta
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => handleOpenRegister('login')}
                    outlined
                    style={{ marginRight: '1rem' }}
                  >
                    Login
                  </Button>
                  <Button onClick={() => handleOpenRegister('register')}>
                    Cadastrar
                  </Button>
                </>
              )}
            </nav>
          </>
        )}
      </Container>
      {windowWidth <= 1024 && (
        <IconButton>
          <SearchIcon
            onClick={() => history.push('/todos-cursos')}
            style={{
              color: '#fff',
            }}
          />
        </IconButton>
      )}
    </Wrapper>
  );
};

export default Header;
