import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import DefaultLayout from '../pages/_layouts/default';
import { store } from '../store';
import ScrollToTop from './ScrollToTop';

const RouteWrapper: React.FC<any> = ({
  component: Component,
  userOnly = false,
  ...rest
}) => {
  const { userData } = store.getState().profile;

  // if (!signed && isPrivate) {
  //   return <Redirect to="/" />;
  // }
  // if (signed && isAuth) {
  //   return <Redirect to="/" />;
  // }
  if (!userData && userOnly) {
    return <Redirect to="/" />;
  }

  // // eslint-disable-next-line no-nested-ternary
  // const Layout = isAuth ? AuthLayout : isAdmin ? AdminLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <ScrollToTop />
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};

export default RouteWrapper;
