import React, { ReactElement, useCallback, useState } from 'react';
import InfoIcon from '@material-ui/icons/Info';
import {
  Tooltip,
  ClickAwayListener,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Clear, Done, PriorityHigh } from '@material-ui/icons';
import InputMask from 'react-input-mask';
import {
  TextInputContainer,
  TextInputWrapper,
  TextInputLabel,
  IconLabel,
  LabelWrapper,
  TooltipButton,
  Input,
  InfoMessage,
} from './styles';

export type TextInputFieldProps = {
  label?: string;
  labelPosition?: 'center' | 'right' | 'left';
  iconRight?: ReactElement;
  iconLeft?: ReactElement;
  select?: boolean;
  options?: Option[];
  selectTitle?: string;
  selectSearchPlaceholder?: string;
  borderRadius?: string;
  borderColor?: string;
  shadow?: string;
  message?: string;
  messageType?: 'warning' | 'success' | 'error';
  multiline?: boolean;
  tooltip?: string;
  mask?: string | (string | RegExp)[];
  small?: boolean;
  changeHandler?: (e: string | React.ChangeEvent<any>) => void;
  showTooltip?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  onRender?: () => void;
  messageColor?: string;
  warningColor?: string;
  labelIcon?: ReactElement;
} & React.InputHTMLAttributes<HTMLInputElement>;

interface Option {
  value: string;
  label: string;
}

type RefType = HTMLInputElement;

export const InfoButton = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>((props, ref) => {
  return (
    <TooltipButton ref={ref} {...props}>
      <InfoIcon />
    </TooltipButton>
  );
});

InfoButton.displayName = 'InfoButton';
export const TextInputField = React.forwardRef<RefType, TextInputFieldProps>(
  (
    {
      iconLeft,
      iconRight,
      label,
      labelPosition,
      onClick,
      id,
      tooltip,
      disabled,
      select,
      value,
      borderRadius,
      shadow,
      mask,
      small,
      multiline,
      messageColor,
      message,
      messageType,
      labelIcon,
      ...props
    },
    ref
  ) => {
    const desktop = useMediaQuery('(min-width:1280px)');
    const theme = useTheme();
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);

    function handleTooltipClose(): void {
      setTooltipVisible(false);
    }

    function handleTooltipOpen(): void {
      setTooltipVisible(true);
    }

    const renderIcon = useCallback(() => {
      switch (messageType) {
        case 'error':
          return <Clear style={{ color: theme.palette.error.main }} />;

        case 'warning':
          return <PriorityHigh style={{ color: theme.palette.warning.main }} />;

        case 'success':
          return <Done style={{ color: theme.palette.success.main }} />;

        default:
          return <></>;
      }
    }, [messageType, theme]);

    return (
      <TextInputWrapper className="input-field" small={small}>
        {label && (
          <LabelWrapper small={small}>
            <TextInputLabel
              small={small}
              htmlFor={id}
              labelPosition={labelPosition}
              messageType={messageType}
            >
              {label}
            </TextInputLabel>

            {!!tooltip && (
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  open={tooltipVisible}
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={handleTooltipClose}
                  title={tooltip}
                  leaveTouchDelay={5000}
                  placement={desktop ? 'right' : 'top'}
                  arrow={desktop}
                  interactive
                  onMouseEnter={(): void => setTooltipVisible(true)}
                >
                  <InfoButton onClick={handleTooltipOpen} type="button" />
                </Tooltip>
              </ClickAwayListener>
            )}
            {labelIcon}
          </LabelWrapper>
        )}
        <TextInputContainer
          onClick={onClick}
          borderRadius={borderRadius}
          shadow={shadow}
          small={small}
          messageType={messageType}
        >
          <IconLabel>
            {iconLeft}
            {multiline ? (
              <Input
                multiline
                value={value}
                disabled={select || disabled}
                autoComplete={
                  process.env.NODE_ENV === 'development' ? undefined : 'off'
                }
                id={id}
                name={id}
                {...props}
                color={undefined}
              />
            ) : (
              <InputMask
                inputRef={ref}
                value={value}
                disabled={select || disabled}
                id={id}
                name={id}
                maskChar={null}
                mask={mask || ''}
                autoComplete={
                  process.env.NODE_ENV === 'development' ? undefined : 'off'
                }
                {...props}
              />
            )}
          </IconLabel>
          {iconRight || renderIcon()}
        </TextInputContainer>
        {message && (
          <InfoMessage
            color={messageColor || theme.palette[messageType || 'primary'].main}
          >
            {message}
          </InfoMessage>
        )}
      </TextInputWrapper>
    );
  }
);

TextInputField.displayName = 'TextInputField';
