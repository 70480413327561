import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: windowWidth, innerHeight: WindowHeight } = window;
  return {
    windowWidth,
    WindowHeight,
  };
}

interface Props {
  windowWidth: number;
  WindowHeight: number;
}

export default function WindowDimensions(): Props {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
