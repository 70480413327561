import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Typography, Divider } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    // height: 240,
    // flexGrow: 1,
    // maxWidth: 400,
    backgroundColor: 'none',
  },
  itemStyle: {
    color: '#2E3571',
    marginBottom: '1rem',
  },
  contentStyle: {
    color: '#000',
    fontSize: '14px',
    marginTop: '2rem',
    marginBottom: '1rem',
  },
  dividerStyle: {
    marginBottom: '1rem',
  },
});

interface Props {
  item: string;
  contents: string[];
}

const TreeViewComponent: React.FC<Props> = ({ item, contents }) => {
  const classes = useStyles();

  return (
    <>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <TreeItem className={classes.itemStyle} nodeId="1" label={item}>
          {contents.map((content) => (
            <Typography
              className={classes.contentStyle}
              key={content}
              variant="body1"
              component="p"
            >
              {content}
            </Typography>
          ))}
        </TreeItem>
      </TreeView>
      <Divider className={classes.dividerStyle} />
    </>
  );
};

export default TreeViewComponent;
