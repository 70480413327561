import { User } from '../../../common/interfaces/user';

export type ProfileState = {
  userData: User | null;
};

export enum UserTypes {
  REHYDRATE = 'persist/rehydrate',
  LOGIN_SUCCESS = '@user/LOGIN_SUCCESS',
  LOGOUT_SUCCESS = '@user/LOGOUT_SUCCESS',
}

export interface UserActionsTypes {
  type: UserTypes;
  payload: any;
}
