import { IconButton, Modal as MuiModal, withTheme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = withTheme(
  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30rem;
    background: #fff;
    border-radius: 10px;
    position: relative;
    padding: 2rem;
    margin: auto;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 36rem;
    }

    .input-field {
      margin-bottom: 1rem;
    }

    > div {
      width: 100%;

      form {
        max-height: 90vh;
        overflow-y: auto;

        &::-webkit-scrollbar-thumb {
          visibility: hidden !important;
        }
      }
    }
  `
);

export const CloseButton = styled(IconButton)`
  position: absolute !important;
  right: 0.17rem;
  top: 0.17rem;
  padding: 0.25rem;
`;

export const Modal = styled(MuiModal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const NavLink = withTheme(styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 1.2rem;
  :hover {
    text-decoration: underline;
  }
`);
