import React, { useCallback } from 'react';
import LoginPopup, { LoginPopupProps } from '../../components/LoginPopup';

const initialState = {
  showPopup: (_state: LoginPopupProps): any => {},
  closePopup: (_event?: any): any => {},
};
export const loginPopupContext = React.createContext(initialState);
const { Provider } = loginPopupContext;

const LoginPopupProvider: React.FC = ({ children }) => {
  const [popupState, setPopupState] = React.useState<LoginPopupProps>({
    isOpen: false,
    step: 'login',
  });

  const close = (): void => {
    setPopupState((p) => ({
      ...p,
      isOpen: false,
      alert: undefined,
      step: 'login',
    }));
  };

  const showPopup = useCallback((state: LoginPopupProps) => {
    ;
    setPopupState({
      ...state,
      isOpen: true,
    });
  }, []);

  const contextValue = {
    showPopup,
    closePopup: close,
  };

  return (
    <>
      <Provider value={contextValue}>{children}</Provider>
      <LoginPopup onClose={close} {...popupState} />
    </>
  );
};

export default LoginPopupProvider;
