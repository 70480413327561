import { CartItem } from '../../../common/interfaces/cartItem';

export type CartState = {
  items: CartItem[];
};

export enum CartTypes {
  REHYDRATE = 'persist/rehydrate',
  ADD_TO_CART = '@CART/ADD_TO_CART',
  REMOVE_FROM_CART = '@CART/REMOVE_FROM_CART',
  CLEAR_CART = '@CART/CLEAR_CART',
}

export interface CartActionsTypes {
  type: CartTypes;
  payload: any;
}
