import { CartItem } from '../../../common/interfaces/cartItem';
import { CartTypes } from './types';

export function addToCart(item: CartItem) {
  return {
    type: CartTypes.ADD_TO_CART,
    payload: { item },
  };
}

export function removeFromCart(id: number) {
  return {
    type: CartTypes.REMOVE_FROM_CART,
    payload: { id },
  };
}

export function clearCart() {
  return {
    type: CartTypes.CLEAR_CART,
  };
}
