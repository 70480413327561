import styled from 'styled-components';
import { Container as MuiContainer, Typography } from '@material-ui/core';
import ScrollBar from 'react-perfect-scrollbar';

export const Container = styled(MuiContainer)`
  padding: 2rem 0;
`;

export const Image = styled.img`
  width: 100%;
  max-height: 300px;
`;

export const InnerContainer = styled.section``;

export const FreeText = styled(Typography)`
  color: #359f43;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 2rem 0;
  > button {
    :first-child {
      margin-right: 1rem;
    }
  }
`;

export const CoursesCaroussel = styled(ScrollBar)`
  display: flex;
  max-width: max-content;
  width: unset;
  padding: 5px 10px;
  div.ps__thumb-x {
    visibility: hidden !important;
  }
`;

export const CourseCardContainer = styled.div`
  margin-right: 2rem;
  &:last-child {
    margin-right: 0;
  }
`;

export const Scroll = styled(ScrollBar)`
  display: flex;
  max-width: max-content;
  height: 31rem;
  width: unset;
  padding: 5px 10px;

  div.ps__thumb-x {
    visibility: hidden !important;
  }
`;

export const CoursesContainer = styled.div`
  display: flex;
`;

export const RelatedCourses = styled.div`
  margin: 2rem 0;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 31rem;
`;
