import {
  ButtonBase,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { formatNumberToBRL } from '../../common/utils/formatNumber';
import MessageBox from '../MessageBox';

import { Container, CourseProduct, CouseName, CoursePrice } from './styles';

interface CoursePriceTagProps {
  title: string;
  price: number;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const CoursePriceTag: React.FC<CoursePriceTagProps> = ({
  price,
  title,
  onClick,
}) => (
  <ButtonBase style={{ width: '100%' }} onClick={onClick}>
    <CourseProduct elevation={3}>
      <CouseName>{title}</CouseName>
      <CoursePrice>{formatNumberToBRL(price)}</CoursePrice>
    </CourseProduct>
  </ButtonBase>
);

interface CourseFinishedPopupProps {
  isOpen: boolean;
  onClose: (event: React.SyntheticEvent<{}, Event>) => void;
  courseName: string;
  grade: number;
  options: {
    title: string;
    price: number;
    onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  }[];
  loading: boolean;
}

const CourseFinishedPopup: React.FC<CourseFinishedPopupProps> = ({
  isOpen,
  onClose,
  courseName,
  grade,
  options,
  loading,
}) => {
  return (
    <MessageBox isOpen={isOpen} onClose={onClose}>
      <Container>
        <Typography variant="h5" gutterBottom>
          Você concluiu o curso {courseName} com uma nota de{' '}
          {grade && grade.toFixed(2)}%!
        </Typography>
        <Typography variant="h6">
          Para solicitar seu certificado, selecione uma das opções:
        </Typography>
        <Grid container spacing={1} style={{ margin: '1rem 0' }}>
          {loading && <CircularProgress />}
          {!loading &&
            options.map((option) => (
              <Grid item xs={12} key={option.title}>
                <CoursePriceTag
                  price={option.price}
                  title={option.title}
                  onClick={option.onClick}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </MessageBox>
  );
};

export default CourseFinishedPopup;
