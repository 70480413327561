import React, { useCallback, useState } from 'react';
import { Form, Formik, Field, FieldProps } from 'formik';
import { Box, Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { TextInputField } from '../../InputField';
import { Step } from '../types';
import certifiqueApi from '../../../services/certifiqueApi';
import { NavLink } from '../styles';

interface RecoverProps {
  setStep: (step: Step) => void;
  onFinish?: () => void;
}

const Recover: React.FC<RecoverProps> = ({ setStep }) => {
  const [result, setResult] = useState<'success' | 'error'>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback((values: any) => {
    setLoading(true);
    certifiqueApi
      .recover(values.email)
      .then(() => {
        setResult('success');
      })
      .catch(() => {
        setResult('error');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {() => (
          <Form>
            <Typography variant="h6" gutterBottom align="center">
              Para recuperar sua conta digite seu email
            </Typography>

            <Field name="email">
              {({ field }: FieldProps) => (
                <TextInputField {...field} label="Email" />
              )}
            </Field>
            <Box
              display="flex"
              justifyContent="space-between"
              m="0 0 1rem"
              p="0 1rem 0"
            >
              <NavLink onClick={() => setStep('login')}>Fazer Login</NavLink>
            </Box>
            <Box display="flex" width="100%" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                Enviar
              </Button>
            </Box>
            {result && (
              <Box m="1rem 0">
                <Alert>
                  {result === 'success'
                    ? 'Enviamos um email com o código cheque sua caixa de entrada!'
                    : 'Não foi possível envia um email, tente novamente'}
                </Alert>
              </Box>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Recover;
