import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  RadioGroupProps,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import { Skeleton } from '@material-ui/lab';
import { Container, Options, QuestionHeader } from './styles';

export interface QuestionOption {
  text: string;
  sequence: number;
}
export interface QuestionProps {
  title: string;
  description?: string;
  options: QuestionOption[];
  type: 'S' | 'M' | 'B';
  value?: number[];
  onChange?: (answers: number[]) => void;
  radioGroupProps?: RadioGroupProps;
}
const Question: React.FC<QuestionProps> = ({
  title,
  description,
  options,
  type,
  value: parentValue,
  onChange,
  radioGroupProps,
}) => {
  const [answers, setAnswers] = useState<number[]>(parentValue || []);

  useEffect(() => {
    if (onChange) {
      onChange(answers);
    }
  }, [answers]);

  useEffect(() => {
    if (parentValue && parentValue !== answers) {
      setAnswers(parentValue);
    }
  }, [parentValue]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value?: string | boolean
  ) => {
    const {
      target: { checked, name },
    } = event;

    if (type === 'M') {
      setAnswers((v) =>
        !checked ? v.filter((e) => e !== Number(name)) : [...v, Number(name)]
      );
    } else {
      setAnswers([Number(value)]);
    }
  };
  return (
    <Container>
      <QuestionHeader>
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="subtitle2">{parse(description || '')}</Typography>
        <Typography variant="caption">
          {type === 'M'
            ? 'Selecione todas respostas que se aplicam'
            : 'Selcione apenas uma resposta'}
        </Typography>
      </QuestionHeader>
      <Options>
        <FormControl component="fieldset">
          {type !== 'M' ? (
            <RadioGroup
              {...radioGroupProps}
              aria-label="question"
              onChange={handleChange}
              color="primary"
              value={String(answers[0])}
            >
              {options.map((option) => (
                <FormControlLabel
                  name={String(option.sequence)}
                  key={option.sequence}
                  value={String(option.sequence)}
                  control={<Radio />}
                  label={option.text}
                />
              ))}
            </RadioGroup>
          ) : (
            options.map((option) => (
              <FormControlLabel
                key={option.sequence}
                control={
                  <Checkbox
                    checked={answers.some((a) => a === option.sequence)}
                    onChange={handleChange}
                    name={String(option.sequence)}
                    color="secondary"
                  />
                }
                label={option.text}
              />
            ))
          )}
        </FormControl>
      </Options>
    </Container>
  );
};

export const QuestionSkeleton: React.FC = () => {
  return (
    <Container>
      <QuestionHeader>
        <Typography variant="h5" gutterBottom>
          <Skeleton width="20%" />
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <Skeleton width="50%" />
          <Skeleton width="50%" />
        </Typography>
        <Typography variant="caption" gutterBottom>
          <Skeleton />
        </Typography>
      </QuestionHeader>
      <Options>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      </Options>
    </Container>
  );
};

export default Question;
