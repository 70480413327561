import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, Button as MuiButton } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { Skeleton } from '@material-ui/lab';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import WindowDimensions from '../../common/hook/WindowDimensions';
import Button from '../../components/Button';
import SubtitleBar from '../../components/SubtitleBar';
import CoursesCard from '../../components/CoursesCard';

import {
  Container,
  ContentContainer,
  Content,
  CategoryContainer,
  CoursesSection,
  Highlight,
} from './styles';
import certifiqueApi from '../../services/certifiqueApi';
import Course from '../../common/interfaces/course';
import NoResults from '../../components/NoResults';
import useLogin from '../../common/hook/useLogin';
import { Taxonomy } from '../../common/interfaces/taxonomy';

const alt = new Array(8);
alt.fill(Math.random);

const Home: React.FC = () => {
  const { windowWidth } = WindowDimensions();
  const location = useLocation<{
    loggedOut?: boolean;
  }>();

  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [categories, setCategories] = useState<Taxonomy[]>([]);

  const login = useLogin();
  const history = useHistory();

  useEffect(() => {
    if (location.state?.loggedOut) {
      location.state.loggedOut = false;

      login.showPopup({
        step: 'login',
        alert: {
          severity: 'warning',
          text: 'Sua sessão expirou, para continuar, faça seu login novamente',
        },
      });
    }
  }, [location.state, login]);

  const getCourses = useCallback(async () => {
    setLoading(true);

    const { content } = await certifiqueApi
      .listSpotlightCourses()
      .finally(() => {
        setLoading(false);
      });
    setCourses(content);
  }, []);

  const getCategories = useCallback(() => {
    setCategoriesLoading(true);

    certifiqueApi
      .getCourseCategory('count')
      .then((res) => {
        setCategories(res.slice(0, 8));
      })
      .finally(() => setCategoriesLoading(false));
  }, []);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  useEffect(() => {
    getCourses();
  }, [getCourses]);

  const handleNavigateWithCategory = (categoryId: string | number) => {
    history.push(`todos-cursos?category=${categoryId}`);
  };

  return (
    <>
      <Helmet title="Certifique cursos - cursos grátis com certificado reconhecido">
        <meta
          name="description"
          content="Faça seu curso gratuitamente em 5 minutos! E tire seu certificado
          hoje mesmo. Cursos de auxiliar de serviços gerais, doula, terapia e
          muito mais!"
        />
      </Helmet>
      <Container>
        <ContentContainer>
          <Content>
            <Typography variant="h2" component="h2">
              Certifique cursos
            </Typography>
            <Box margin="1.5rem 0 1.5rem 0">
              <Typography variant="h1" component="h1">
                Aqui você encontra vários <Highlight>cursos grátis</Highlight>,
                com certificado válido em todo território nacional!
              </Typography>
              {windowWidth <= 600 && (
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={() => login.showPopup({ step: 'register' })}
                  style={{
                    marginTop: 10,
                  }}
                >
                  Cadastrar
                </MuiButton>
              )}
            </Box>
            <Typography variant="subtitle1">CATEGORIAS MAIS VISTAS</Typography>
            <SubtitleBar />
          </Content>
          <CategoryContainer>
            <Grid container spacing={2} justify="center" alignItems="center">
              {!categoriesLoading
                ? categories.map((category) => (
                    <Grid
                      key={category.term_id}
                      item
                      xs={12}
                      sm={6}
                      md={6}
                      lg={3}
                    >
                      <Button
                        colortype="blue"
                        onClick={() =>
                          handleNavigateWithCategory(category.term_id)
                        }
                      >
                        {' '}
                        {category.name}
                      </Button>
                    </Grid>
                  ))
                : alt.map((e) => (
                    <Grid key={e()} item xs={12} sm={6} md={4} lg={3}>
                      <Button colortype="blue">
                        <Skeleton width="100%" height={20} />
                      </Button>
                    </Grid>
                  ))}
            </Grid>
          </CategoryContainer>
        </ContentContainer>
        <CoursesSection>
          <Typography variant="h2">Cursos mais populares</Typography>
          <Grid container spacing={2} alignItems="center">
            {!loading
              ? courses.map((course) => (
                  <Grid key={course.id} item xs={12} sm={6} md={4} lg={3}>
                    <CoursesCard
                      title={course.name}
                      subtitle={`${course.duration} horas`}
                      image={course.image}
                      onClickCard={() => history.push(`/course/${course.slug}`)}
                      buttonText="Ver curso"
                      buttonProps={
                        {
                          variant: 'contained',
                          color: 'primary',
                          to: `/course/${course.slug}` as any,
                          component: Link as any,
                        } as any
                      }
                    />
                  </Grid>
                ))
              : alt.map((e) => (
                  <Grid key={e()} item xs={12} sm={6} md={4} lg={3}>
                    <Skeleton variant="rect" width={295} height={295} />
                  </Grid>
                ))}
          </Grid>

          {!loading && !courses.length && <NoResults />}
        </CoursesSection>
        <CoursesSection>
          <Typography variant="h2">Cursos Livres</Typography>
          <Typography variant="h6">
            Os cursos são de nível básico, classificados como livres, de
            formação inicial, aperfeiçoamento e/ou atualização Nossos cursos são
            amparados conforme Lei nº 9.394/96, Decreto Presidencial n°
            5.154/2004 e Resolução CNE/CEB nº 04/99 O acesso aos cursos, a
            plataforma de estudos e o suporte ao aluno é totalmente gratuito A
            emissão do certificado de conclusão é opcional e contém uma pequena
            taxa de R$ 43,90
          </Typography>
        </CoursesSection>
        <CoursesSection>
          <Typography variant="h2">Nossos cursos</Typography>
          <Typography variant="h6">
            A Certifique Cursos é uma escola de cursos online gratuitos que,
            além de promover um ensino de excelente qualidade, também oferece
            aos seus alunos um certificado válido em todo o território nacional
            com o menor custo do mercado. O certificado pode ser usado para
            horas complementares da faculdade, concursos e muito mais!
            Cadastra-se e venha estudar conosco!
          </Typography>
        </CoursesSection>
      </Container>
    </>
  );
};

export default Home;
