import { Search } from '@material-ui/icons';
import React from 'react';
import { Container, Input } from './styles';

interface SearchBarProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onChange }) => {
  return (
    <Container>
      <Input name="search-bar-input" onChange={onChange} />
      <Search />
    </Container>
  );
};

export default SearchBar;
