import Axios, { AxiosInstance } from 'axios';
import { format } from 'date-fns';
import { AnswerSheet } from '../../common/interfaces/answerSheet';
import { CartItem } from '../../common/interfaces/cartItem';
import Course from '../../common/interfaces/course';
import CourseObject from '../../common/interfaces/courseOBject';
import { FinishedCourse } from '../../common/interfaces/finishedCourse';
import { Pageable } from '../../common/interfaces/pageable';
import { Product } from '../../common/interfaces/product';
import { User } from '../../common/interfaces/user';
import CancelableRequest from '../CancelableRequest';
import { logoutUser } from '../logoutUser';
import { openLightBox } from '../pagseguro';
import { clearToken, getToken, storeToken } from '../token';
import api from './api';
import { Order } from '../../common/interfaces/order';
import { Taxonomy } from '../../common/interfaces/taxonomy';
import Faq from '../../common/interfaces/faq';

export interface ListAll {
  q?: string;
  _page?: number;
  _limit?: number;
  categories?: number;
}

interface Update {
  name: string;
  password?: string;
  phone_number?: string;
}

interface RegisterProps {
  cpf: string;
  name: string;
  password: string;
  email: string;
  phone_number: string;
}

interface CertificateCode {
  code: string;
}

class CertifiqueApi {
  private api: AxiosInstance;

  private cancelable: CancelableRequest;

  private apiBase: string;

  private authbase: string;

  constructor(axiosInstance: AxiosInstance) {
    this.api = axiosInstance;
    this.apiBase = 'api';
    this.authbase = 'jwt-auth/v1';
    this.cancelable = new CancelableRequest();
    this.setToken();
  }

  setToken = () => {
    const token = getToken();
    if (token) {
      this.api.defaults.headers.Authorization = `Bearer ${token}`;
    }

    api.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.code &&
          err.response.data.code === 'jwt_auth_invalid_token'
        ) {
          clearToken();
          this.api.defaults.headers = {};
          logoutUser();
        }

        throw err;
      }
    );
  };

  listAllCourses = async (config?: ListAll): Promise<Pageable<Course>> => {
    return Axios.get(
      `${process.env.REACT_APP_API_URL}/wp-json/api/courses`,
      this.cancelable.once({
        params: config,
      })
    ).then((res) => res.data);
  };

  listSpotlightCourses = async (): Promise<Pageable<Course>> => {
    return Axios.get(
      `${process.env.REACT_APP_API_URL}/wp-json/api/courses/spotlight`,
      this.cancelable.once({})
    ).then((res) => res.data);
  };

  listCourseInfo = async (id: number | string): Promise<Course> => {
    return this.api.get(`${this.apiBase}/course/${id}`).then((res) => res.data);
  };

  getQuiz = async (id: string | number) => {
    return this.api.get(`${this.apiBase}/quiz/${id}`).then((res) => res.data);
  };

  validateQuiz = async (
    answerSheet: AnswerSheet[],
    quiz_id: string | number,
    course_id: string | number
  ): Promise<FinishedCourse> => {
    const date = format(new Date(), 'yyyy-MM-dd');
    return this.api
      .post(`${this.apiBase}/course/validate`, {
        answers_sheet: answerSheet,
        quiz_id,
        course_id,
        date,
      })
      .then((res) => {
        return res.data;
      });
  };

  getProducts = async (): Promise<Product[]> => {
    return this.api.get(`${this.apiBase}/products`).then((res) => res.data);
  };

  login = async (
    username: string,
    password: string
  ): Promise<{
    user: User;
  }> => {
    return this.api
      .post(`${this.authbase}/token`, {
        username,
        password,
      })
      .then(async (res) => {
        this.api.defaults.headers.Authorization = `Bearer ${res.data.token}`;
        storeToken(res.data.token);

        return this.api.get(`${this.apiBase}/user`).then((userResponse) => {
          return {
            user: userResponse.data,
          };
        });
      });
  };

  register = async ({
    email,
    cpf,
    password,
    name,
    phone_number,
  }: RegisterProps) => {
    return this.api.post(`${this.apiBase}/register`, {
      email,
      cpf,
      password,
      name,
      phone_number,
    });
  };

  checkout = async (cartItems: CartItem[]): Promise<any> => {
    return this.api
      .post(`${this.apiBase}/checkoutPagSeguro`, { items: cartItems })
      .then(async (res) => {
        if (res && res.data) {
          openLightBox(res.data.code);
        }
      });
  };

  updateUser = async ({
    name,
    password,
    phone_number,
  }: Update): Promise<User> => {
    return this.api
      .put(`${this.apiBase}/user`, {
        name,
        password,
        phone_number: phone_number
          ? phone_number.replace(/\D/g, '')
          : undefined,
      })
      .then((res) => res.data);
  };

  getUserCourses = async (): Promise<{
    courses: Course[];
    finished_courses: FinishedCourse[];
  }> => {
    const token = getToken();
    return this.api
      .get(`${this.apiBase}/courses/finished`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data);
  };

  getUser = async (): Promise<User> => {
    const token = getToken();

    return this.api
      .get(`${this.apiBase}/user`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => res.data);
  };

  getCertificate = async ({ code }: CertificateCode): Promise<CourseObject> => {
    const token = getToken();

    return Axios.get(
      `${process.env.REACT_APP_API_URL}/wp-json/api/certificate`,
      {
        params: { code },
        headers: token ? { Authorization: `Bearer ${token}` } : {},
      }
    ).then((res) => res.data);
  };

  getUserOrders = async (): Promise<Order[]> => {
    return this.api.get(`${this.apiBase}/orders`).then((res) => res.data);
  };

  getCourseCategory = async (orderby?: string): Promise<Taxonomy[]> => {
    return Axios.get(
      `${process.env.REACT_APP_API_URL}/wp-json/api/courseCategories`,
      {
        params: {
          orderby,
        },
      }
    ).then((res) => {
      if (typeof res.data === 'object') {
        return Object.values(res.data);
      }
      return res.data;
    });
  };

  getFaq = async (): Promise<Faq[]> => {
    return Axios.get(`${process.env.REACT_APP_API_URL}/wp-json/api/faq`).then(
      (res) => res.data || []
    );
  };

  recover = async (email: string): Promise<void> => {
    return Axios.post(`${process.env.REACT_APP_API_URL}/wp-json/api/recover`, {
      email,
    }).then((res) => res.data);
  };
}

export default new CertifiqueApi(api);
