import { combineReducers } from 'redux';
import { enableMapSet } from 'immer';
import profile from './profile/reducer';
import cart from './cart/reducer';
import { ApplicationState } from '../types';

enableMapSet();

const reducers = combineReducers<ApplicationState>({
  profile,
  cart,
});

export default reducers;
