import React, { useState, useCallback } from 'react';
import Alert from '@material-ui/lab/Alert';

import { useHistory } from 'react-router-dom';

import certifiqueApi from '../../services/certifiqueApi';

import Button from '../../components/Button';
import SearchBar from '../../components/SearchBar';

import { Container, Content } from './styles';

const SearchCertificate: React.FC = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [error, setError] = useState(null);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setError(null);
      setSearch(e.target.value);
    },
    []
  );

  const handleSearchCode = useCallback(async () => {
    await certifiqueApi
      .getCertificate({ code: search })
      .then((res) => {
        if (res) {
          return history.push(`/certificate?code=${search}`);
        }
        return history.push(`/certificate?code=${search}`);
      })
      .catch((err) => {
        setError(err);
      });
  }, [history, search]);

  return (
    <Container>
      <Alert severity="info">
        Os certificados iniciados em <strong>552</strong> devem ser consultados
        através do email <strong>contato@certifiquecursos.com.br</strong>
      </Alert>
      <h1>Validar Certificado</h1>
      <Content>
        <SearchBar onChange={handleSearchChange} />
        <Button colortype="blue" onClick={handleSearchCode}>
          Validar
        </Button>
      </Content>

      {!!error && (
        <Alert severity="error">
          O certificado informado não foi encontrado!
        </Alert>
      )}
    </Container>
  );
};

export default SearchCertificate;
