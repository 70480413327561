import { createGlobalStyle } from 'styled-components';
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import '@fortawesome/fontawesome-free/css/all.css';
// import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,900&display=swap');
  @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus{
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    font-size: 62.5%;
    background-color: #fff
  }

  body {
    --webkit-font-smoothing: antialised;

    padding-top: 67px;

    @media (min-width: 1024px) {
      padding-top: 76px;

  }

  }

  body, input, button, nav {
    font: 14px 'Roboto', sans-serif;
  }

  * {
    text-decoration: none;
  }

  /* ul {
    list-style: none;
  } */

  button {
    cursor: pointer;
  }

  .Toastify__toast-container{
    font-size: 1.5rem;
  }
`;
