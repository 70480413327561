import { Box, Typography } from '@material-ui/core';
import React from 'react';

// import { Container } from './styles';

const NoResults: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      width="100%"
    >
      <Typography variant="h4">Não há resultados para exibir</Typography>
    </Box>
  );
};

export default NoResults;
