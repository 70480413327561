import { Badge, Box, IconButton, Paper } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import useCart from '../../common/hook/useCart';
import { ApplicationState } from '../../store/types';

import { Container } from './styles';

interface CartIconButtonProps {
  onClick?:
    | ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void)
    | undefined;
}

const CartIconButton: React.FC<CartIconButtonProps> = ({ onClick }) => {
  const cart = useSelector((state: ApplicationState) => state.cart);

  return (
    <Container>
      <IconButton onClick={onClick}>
        <Paper
          style={{
            borderRadius: 100,
            padding: '1rem 1.3rem',
            position: 'relative',
          }}
        >
          <Badge badgeContent={cart.items.length} color="secondary">
            <ShoppingCart color="primary" fontSize="large" />
          </Badge>
        </Paper>
      </IconButton>
    </Container>
  );
};

export default CartIconButton;
