import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { Box, Button, ButtonProps } from '@material-ui/core';
import parse from 'html-react-parser';
import SubtitleBar from '../SubtitleBar';

import teste from '../../assets/teste.jpg';

const useStyles = makeStyles({
  root: {
    maxWidth: 320,
    margin: 'auto',
  },
  media: {
    width: 320,
    height: 200,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subtitle: {
    marginTop: 10,
  },
});

interface CardProps {
  image?: string;
  title: string;
  subtitle?: string;
  buttonText?: string;
  buttonProps?: ButtonProps;
  onClickCard?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
}

const CoursesCard: React.FC<CardProps> = ({
  title,
  subtitle,
  buttonProps,
  buttonText,
  image,
  onClickCard,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.root} elevation={3}>
      <CardActionArea onClick={onClickCard}>
        <CardMedia
          component="img"
          className={classes.media}
          image={(!image && teste) || undefined}
          src={image}
          title={title}
        />
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h5"
            component="h5"
            noWrap
            style={{ maxWidth: '100%' }}
          >
            {title}
          </Typography>
          <SubtitleBar />
          {subtitle && (
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className={classes.subtitle}
              align="justify"
            >
              {parse(subtitle)}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      {buttonText && (
        <Box
          width="100%"
          display="flex"
          justifyContent="center"
          marginBottom="1rem"
        >
          <Button {...buttonProps}>{buttonText}</Button>
        </Box>
      )}
    </Card>
  );
};

export default CoursesCard;
