import { WhatsApp } from '@material-ui/icons';
import React from 'react';

import { SiWhatsapp } from 'react-icons/si';
import { Container } from './styles';

const WhatsappIcon: React.FC = () => {
  return (
    <Container
      href="https://api.whatsapp.com/send?phone=5535988660200"
      target="_blank"
      rel="noreferrer"
      color="inherit"
    >
      <div>
        <SiWhatsapp color="#fff" size={50} />
      </div>
    </Container>
  );
};

export default WhatsappIcon;
